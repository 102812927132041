import { FC } from 'react';

interface Properties {
  children: any;
  buttonText: string;
  buttonAction: any;
}

const Modal: FC<Properties> = ({ children, buttonText, buttonAction }) => {
  return (
    <div className="grid justify-items-center md:p-6 gap-3">
      {children}
      <button
        onClick={() => buttonAction()}
        className="grid items-center py-2 px-5 mt-8 mb-4 uppercase font-medium shadow-lg rounded-full bg-blue-300 hover:bg-blue-400 hover:-translate-y-1 hover:cursor-pointer duration-200"
      >
        {buttonText}
      </button>
    </div>
  );
};

export default Modal;
