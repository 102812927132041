import Vimeo from '@u-wave/react-vimeo';

import { store, useAppSelector } from '../stores/AppStore';
import { popupVideoUrl } from '../constants';
import { setDidWatchWelcomeVideo } from '../features/global/globalSlice';

const Welcome = () => {
  const user = useAppSelector((state) => state.user.user);
  const welcomeText = user?.firstName
    ? `Welcome, ${user.firstName}!`
    : 'Welcome!';
  return (
    <div
      data-testid="welcome-video"
      className="
        grid grid-cols-1 md:grid-cols-2
        gap-2 md:gap-4 p-4 md:p-6
        w-full h-full items-center
        bg-gray-100 rounded-xl
      "
    >
      <div className="flex flex-col md:flex-row gap-2 md:gap-4 w-full items-center">
        <img
          src="/images/icons/lightbulb.svg"
          alt="lightbulb"
          className="px-2 md:px-4 h-20 md:h-24 lg:h-32"
        />
        <div className="flex flex-col gap-2 text-center md:text-start">
          <h1 className="text-2xl font-bold">{welcomeText}</h1>
          <p className="text-gray-500">
            Watch this video to discover how to get the most out of the Fill the
            Math GAPS program.
          </p>
          <p className="hidden md:block font-medium">
            Have more questions?&nbsp;
            <a
              href="mailto:help@thinktankprep.com?subject=General Inquiry"
              target="_blank"
              className="text-tttDefault hover:underline"
              rel="noreferrer"
            >
              Contact us.
            </a>
          </p>
        </div>
      </div>
      <div className="w-full md:w-[80%] lg:w-[90%] xl:w-[70%] justify-self-end">
        <Vimeo
          video={popupVideoUrl} // TODO: check if this is the right video
          responsive
          controls={true}
          speed={true}
          onProgress={(event) => {
            if (event.percent > 0.75) {
              store.dispatch(setDidWatchWelcomeVideo(true));
            }
          }}
        />
      </div>
      <p className="block md:hidden text-center">
        Have more questions?&nbsp;
        <a
          href="mailto:help@thinktankprep.com?subject=General Inquiry"
          target="_blank"
          className="text-tttDefault hover:underline"
          rel="noreferrer"
        >
          Contact us.
        </a>
      </p>
    </div>
  );
};

export default Welcome;
