import { FC, useEffect, useState } from 'react';
import Select from 'react-select';
import { useParams, useSearchParams } from 'react-router-dom';
import { push } from 'redux-first-history';

import { UserDocument } from '../types';
import { FilterType, ROLES, Subject } from '../constants';
import {
  apiSlice,
  useGetSkillsQuery,
  useGetStudentOrThinkerSummaryDataQuery,
  useGetThinkersOfMentorQuery,
  useGetTutorialsQuery,
} from '../services/apiSlice';
import { store, useAppSelector } from '../stores/AppStore';
import { selectHasOneOfTheRoles } from '../features/user/userSlice';

import BreakoutCircular from '../components/BreakoutCircular';
import Filter, { FilterValue } from '../components/Filter';
import InfiniteScroll from '../components/InfiniteScroll';
import { Loading, LoadingBlock } from '../components/Loading';
import TutorialCard from '../components/TutorialCard';

interface TutorialsState {
  skills: string[];
  skip: number;
  subject: Subject;
  thinker?: { label: string; value: UserDocument };
}

const Tutorials: FC = () => {
  const [searchParameters, setSearchParameters] = useSearchParams();
  const { subject: subjectParameter } = useParams();
  const searchParameterSkills = searchParameters.getAll('skills');
  const [tutorials, setTutorials] = useState<TutorialsState>({
    skills: searchParameterSkills ?? [],
    skip: 0,
    subject: (subjectParameter as Subject) ?? Subject.geometry,
  });
  const { user } = useAppSelector((state) => state.user);
  const isStudentOrThinker = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.student, ROLES.thinker]),
  );
  const isMentor = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.mentor]),
  );
  const {
    data: studentOverviewData,
    isFetching: isFetchingStudentResultOverview,
  } = useGetStudentOrThinkerSummaryDataQuery(
    { shouldFetchTrack: false },
    { skip: !isStudentOrThinker },
  );
  const { data: thinkers, isFetching: isFetchingThinkers } =
    useGetThinkersOfMentorQuery(
      { mentorId: user?._id ?? '' },
      { skip: !user || !isMentor },
    );
  const {
    data: skillsData,
    isError: isSkillsError,
    isFetching: isSkillsFetching,
    isLoading: isSkillsLoading,
  } = useGetSkillsQuery(
    {
      subject: tutorials.subject,
      trackId: tutorials.thinker?.value?.followsTracks?.[0].track,
    },
    { skip: isMentor && !tutorials.thinker?.value },
  );
  const {
    data,
    isError,
    isLoading,
    isFetching: isFetchingTutorials,
  } = useGetTutorialsQuery(
    {
      skills: tutorials.skills,
      trackId: tutorials.thinker?.value?.followsTracks?.[0].track,
      skip: tutorials.skip,
      subject: tutorials.subject,
      limit: window.innerWidth > 1200 ? 9 : 4,
    },
    {
      skip: isMentor && !tutorials.thinker?.value,
    },
  );
  const prefectGetTutorials = apiSlice.usePrefetch('getTutorials');

  const areVideosAvailable =
    !isStudentOrThinker ||
    (studentOverviewData && studentOverviewData.subjectResults.length > 0) ||
    // fallback in case of no results but past quizzes provide access
    (!!data && data.total > 0);
  const thinkerFilterOptions =
    thinkers?.map((thinker) => ({
      label: `${thinker.firstName} ${thinker.lastName}`,
      value: thinker,
    })) ?? [];
  const subjectFilterOptions = Object.values(Subject);

  const studentSubjectData =
    !!studentOverviewData &&
    Array.isArray(studentOverviewData?.subjectResults) &&
    studentOverviewData.subjectResults.length > 0
      ? studentOverviewData.subjectResults.map((item) => ({
          label: item.subject,
          value: item.result,
        }))
      : // fallback in case of no results but past quizzes provide access
        subjectFilterOptions.map((option) => ({
          label: option,
          value: undefined,
        }));

  const skillsPlaceholder = isSkillsError
    ? 'Sorry, Skill filtering is temporarily unavailable'
    : isSkillsLoading
    ? 'Skills list is loading...'
    : 'Click to filter tutorials by skills...';

  useEffect(() => {
    if (!subjectParameter) {
      store.dispatch(push(`/tutorials/${Subject.geometry}`));
    }
  }, []);

  useEffect(() => {
    if (isError) {
      setTutorials((t) => ({
        skills: t.skills,
        skip: 0,
        thinker: t.thinker,
        subject: (subjectParameter as Subject) ?? Subject.geometry,
      }));
    }
  }, [isError]);

  useEffect(() => {
    if (
      JSON.stringify(searchParameterSkills) !== JSON.stringify(tutorials.skills)
    ) {
      setTutorials((t) => ({
        skills: searchParameterSkills ?? [],
        skip: 0,
        thinker: t.thinker,
        subject: t.subject,
      }));
    }

    if (subjectParameter !== tutorials.subject) {
      setTutorials((t) => ({
        skills: t.skills,
        skip: 0,
        subject: (subjectParameter as Subject) ?? Subject.geometry,
      }));
    }
  }, [subjectParameter, searchParameterSkills]);

  useEffect(() => {
    if (thinkers && thinkers.length > 0) {
      setTutorials((t) => ({
        skills: t.skills,
        skip: 0,
        subject: t.subject,
        thinker: {
          label: `${thinkers[0].firstName} ${thinkers[0].lastName}`,
          value: thinkers[0],
        },
      }));
    }
  }, [isFetchingThinkers]);

  if (isFetchingStudentResultOverview) {
    return <Loading />;
  } else if (isStudentOrThinker && !studentSubjectData) {
    return <div>Failed to fetch student subject overview!</div>;
  }

  return (
    <div className="grid gap-12">
      <div className="grid grid-cols-4 gap-6 px-6 pb-6 pt-3 shadow-2xl rounded-xl border-tttDefault border-[1px]">
        <div className="grid justify-self-center col-span-4 max-w-[90%] justify-items-center items-center text-center overflow-hidden uppercase text-white font-bold md:text-xl lg:text-2xl p-3 -mt-7 shadow-md rounded-xl bg-tttDefault">
          Video tutorials
        </div>
        <div className="absolute right-8">
          {(isLoading || isSkillsFetching || isFetchingTutorials) && (
            <Loading />
          )}
        </div>
        <div
          data-testid={'gaps-section'}
          className="box col-start-1 col-span-4 text-center justify-items-center"
        >
          {isMentor && thinkers && (
            <div className="grid col-span-4 justify-items-center p-2 md:p-4">
              <Filter
                filterType={'student-filter' as FilterType}
                options={thinkerFilterOptions}
                onSelect={(option) => {
                  setTutorials((t) => ({
                    skills: [],
                    skip: 0,
                    subject:
                      t.subject ??
                      (subjectParameter as Subject) ??
                      Subject.geometry,
                    thinker: option,
                  }));
                }}
                selected={tutorials.thinker ?? undefined}
              />
            </div>
          )}
          {isStudentOrThinker && studentSubjectData ? (
            <div className="grid grid-cols-2 md:grid-cols-4 gap-12 p-6 w-full">
              <BreakoutCircular
                data={studentSubjectData}
                selected={tutorials.subject}
                isSelectable={true}
                onSelect={(subject) => {
                  setTutorials((previousState) => ({
                    ...previousState,
                    subject: subject as Subject,
                  }));
                  store.dispatch(push(`/tutorials/${subject}`));
                }}
                onHover={(subject) => {
                  prefectGetTutorials({
                    skills: tutorials.skills,
                    skip: tutorials.skip,
                    subject: subject as Subject,
                    limit: window.innerWidth > 1200 ? 9 : 4,
                  });
                }}
              />
            </div>
          ) : (
            <div className="grid col-span-4 justify-items-center p-2 md:p-4">
              <Filter
                filterType={FilterType.subjectFilter}
                options={subjectFilterOptions.map((option) => ({
                  label: option,
                  value: option,
                }))}
                onSelect={(option) => {
                  setTutorials({
                    skills: [],
                    skip: 0,
                    thinker: tutorials.thinker,
                    subject: option.value,
                  });
                  store.dispatch(push(`/tutorials/${option.value}`));
                }}
                selected={
                  {
                    label: tutorials.subject,
                    value: tutorials.subject,
                  } as FilterValue
                }
              />
            </div>
          )}
          {areVideosAvailable ? (
            <>
              <div className="text-sm text-slate-500">
                You can choose one or more skills to filter the videos. All
                videos related to at least one of the selected skills will be
                shown.
              </div>
              <div className="grid col-span-4 py-6">
                <Select
                  value={tutorials.skills.map((s) => {
                    return { value: s, label: s };
                  })}
                  onChange={(selectedSkills) => {
                    const skills = selectedSkills.map((skill) => skill.value);
                    setTutorials((t) => ({
                      skills,
                      skip: 0,
                      thinker: t.thinker,
                      subject: t.subject,
                    }));
                    setSearchParameters({ skills });
                  }}
                  isMulti
                  placeholder={skillsPlaceholder}
                  options={skillsData?.map((s) => {
                    return { value: s.skill, label: s.skill };
                  })}
                  className="w-[90%] md:w-[40%] justify-self-center text-left"
                />
              </div>
            </>
          ) : (
            <div
              data-testid="no-tutorials"
              className="col-span-3 text-slate-600"
            >
              You don't have any tutorial videos to watch at the moment.
              <br />
              Come back each week as you make progress in the program.
            </div>
          )}
        </div>
      </div>
      {areVideosAvailable && (
        <div className="grid grid-cols-4 gap-6 px-6 pb-6 pt-3 shadow-2xl rounded-xl border-tttDefault border-[1px]">
          <div className="grid justify-self-center col-span-4 max-w-[90%] justify-items-center items-center text-center overflow-hidden uppercase text-white font-bold md:text-xl lg:text-2xl p-3 -mt-7 shadow-md rounded-xl bg-tttDefault">
            {tutorials.subject} tutorial videos
          </div>
          {!isLoading && !!data ? (
            <InfiniteScroll
              loadMore={() => {
                setTutorials((t) => ({
                  skills: t.skills,
                  skip: data.tutorials.length,
                  thinker: t.thinker,
                  subject: t.subject,
                }));
              }}
              isLoading={isFetchingTutorials}
              hasMore={data.hasMore}
              loader={
                <div className="col-span-1 sm:col-span-2 xl:col-span-3">
                  Loading...
                </div>
              }
              className="grid col-start-1 col-span-4 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 w-full gap-4 p-2 sm:p-4 min-h-[500px] place-items-center"
            >
              {data.tutorials.length === 0 ? (
                <div className="col-span-3">
                  You don't have any {tutorials.subject} tutorial videos to
                  watch at the moment.
                  <br />
                  Come back each week as you make progress in the program.
                </div>
              ) : (
                data.tutorials.map((tutorial, index) => (
                  <TutorialCard {...tutorial} key={index} />
                ))
              )}
            </InfiniteScroll>
          ) : (
            <LoadingBlock />
          )}
        </div>
      )}
      <div className="hidden workaround w-[240px] w-[260px] w-[280px] w-[320px] w-[380px]" />
    </div>
  );
};

export default Tutorials;
