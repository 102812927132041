import { FC } from 'react';
import { FilterType } from '../constants';

export type FilterValue<T = any> = {
  label: string;
  value: T;
};
interface FilterProperties {
  options: FilterValue[];
  filterType: FilterType;
  onSelect: (value: FilterValue) => void;
  onHover?: (value: FilterValue) => void;
  selected?: FilterValue;
}

const Filter: FC<FilterProperties> = ({
  options,
  filterType,
  onSelect,
  onHover,
  selected,
}: FilterProperties) => {
  const isSelected = (option: FilterValue) =>
    selected?.value === option.value ? true : false;

  return (
    <div
      data-testid={`filter-${filterType}`}
      className={`flex flex-wrap gap-2 cursor-pointer capitalize max-h-[200px] overflow-y-auto`}
    >
      {options.map((option) => (
        <div
          key={JSON.stringify(option.value)}
          data-testid={`filterOption-${option.value}`}
          className={`rounded-lg py-2 px-4 whitespace-nowrap cursor-pointer hover:bg-tttDefault hover:text-white transition-all duration-100 ease-in-out ${
            isSelected(option)
              ? 'bg-tttDefault text-white'
              : 'bg-white text-black'
          }`}
          onClick={() => onSelect(option)}
          onMouseOver={
            typeof onHover === 'function' ? () => onHover(option) : undefined
          }
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};

export default Filter;
