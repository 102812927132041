import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { PricingPerLicenseCount } from '../constants';
import { handleError } from '../services/ErrorService';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getPrice(licenseCount: number): number {
  if (!PricingPerLicenseCount[licenseCount]) {
    handleError(new Error('Invalid price check for the number of licenses'));
    return -1;
  }
  return PricingPerLicenseCount[licenseCount];
}

export function getPricePerLicense(licenseCount: number): number {
  return licenseCount === 1
    ? getPrice(licenseCount)
    : Math.round(getPrice(licenseCount) / licenseCount);
}

export function getDiscountPercentage(licenseCount: number): number {
  if (!PricingPerLicenseCount[licenseCount]) {
    return 0;
  }
  if (!PricingPerLicenseCount[1]) {
    handleError(new Error('Invalid price check for a single license'));
    return 0;
  }
  const unitPrice = PricingPerLicenseCount[1];
  const normalPrice = unitPrice * licenseCount;
  return Math.round(
    (1 - PricingPerLicenseCount[licenseCount] / normalPrice) * 100,
  );
}

type Amounts = {
  amount?: number;
  discount?: number;
  tax?: number;
  total?: number;
};

export function getTotal(amounts?: Amounts): number | undefined {
  if (!amounts?.amount) {
    return undefined;
  }
  let total = amounts.amount;
  if (amounts.discount) {
    total = total - amounts.discount;
  }
  if (amounts.tax) {
    total = total + amounts.tax;
  }
  return total;
}
