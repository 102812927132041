import { type FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Controller, useForm } from 'react-hook-form';
import { EyeOffIcon } from 'lucide-react';
import { EyeIcon } from 'lucide-react';
import { StarIcon } from '@heroicons/react/solid';

import { useLoginMutation } from '../services/apiSlice';
import { AppState, LoginCredentials } from '../types';
import { store, useAppSelector } from '../stores/AppStore';
import { logout } from '../features/user/userSlice';
import { setAppState } from '../features/global/globalSlice';
import { resetQuizState } from '../features/quiz/quizSlice';
import { emailPattern } from '../constants';

const Login: FC = () => {
  const navigate = useNavigate();
  const token = useAppSelector((state) => state.user.token);
  const [showPassword, setShowPassword] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<LoginCredentials>({
    mode: 'all',
    defaultValues: { username: '', password: '' },
  });
  const [login, { error, isError, isLoading }] = useLoginMutation();

  const assignmentsCompleted = '62,500+';
  const rating = '5.0';
  const quizzes = '35+';

  useEffect(() => {
    if (token) {
      store.dispatch(logout());
      store.dispatch(resetQuizState());
      store.dispatch(setAppState(AppState.init));
      toast('You have been logged out.', { id: 'logout' });
    }
    navigate('/login');
  }, []);

  const onLogin = async (data: LoginCredentials) => {
    if (!data.username || !data.password) {
      toast.error('Email and password must be provided!');
      return;
    }
    await login(data);
  };

  return (
    <div className="grid md:grid-cols-2 h-full bg-gray-100 -mx-10">
      <div className="relative order-2 md:order-1">
        <img
          src="/images/Matt-teaching.png"
          alt="Mentor"
          className="h-full max-h-screen w-full object-cover"
        />
        <div className="absolute top-[8%] right-[15%] flex items-center">
          <img
            src="/images/icons/assignment.svg"
            className="relative -top-14 left-[50%] right-[50%] w-[40px] h-[40px] rounded-full bg-blue-100 p-2 self-center"
          />
          <div className="text-center bg-white p-4 md:p-6 rounded-lg">
            <div className="text-3xl font-bold text-tttDefault">{quizzes}</div>
            <div className="text-gray-500">Interactive Quizzes</div>
          </div>
        </div>
        <div className="absolute bottom-[15%] left-[5%] flex items-center">
          <StarIcon className="relative -top-14 left-[50%] right-[50%] w-[40px] h-[40px] rounded-full bg-amber-100 p-2 self-center text-amber-400" />
          <div className="text-center bg-white p-4 md:p-6 rounded-lg">
            <div className="text-3xl font-bold text-amber-400">{rating}</div>
            <div className="text-gray-500">Mentor Rating</div>
          </div>
        </div>
        <div className="absolute bottom-[10%] right-[10%] flex items-center">
          <img
            src="/images/icons/student.svg"
            className="relative -top-14 left-[50%] right-[50%] w-[40px] h-[40px] rounded-full bg-blue-100 p-2 self-center"
          />
          <div className="text-center bg-white p-4 md:p-6 rounded-lg">
            <div className="text-3xl font-bold text-tttDefault">
              {assignmentsCompleted}
            </div>
            <div className="text-gray-500">Assignments Completed</div>
          </div>
        </div>
      </div>
      <div className="order-1 md:order-2 flex items-center justify-center bg-gray-100 p-10 lg:p-24">
        <div className="flex flex-col gap-6 w-full max-w-sm h-full">
          <img
            src="/images/logo-transparent.png"
            alt="The Think Tank Logo"
            className="w-[150px] mx-auto"
          />
          <h2 className="text-3xl font-medium text-center">Welcome Back!</h2>
          <form
            onSubmit={handleSubmit(onLogin)}
            className="flex flex-col gap-4 md:gap-6"
          >
            <div className="flex flex-col gap-1 md:gap-2">
              <p className="font-medium">Email</p>
              <Controller
                render={({ field }) => (
                  <input
                    {...field}
                    id="username"
                    data-testid="email-input"
                    placeholder="Enter Email"
                    className="border border-gray-300 rounded-md px-3 py-2 w-full"
                    onCopy={(event) => event.preventDefault()}
                    onCut={(event) => event.preventDefault()}
                    onPaste={(event) => event.preventDefault()}
                  />
                )}
                name="username"
                control={control}
                rules={{
                  required: true,
                  pattern: emailPattern,
                }}
              />
              {errors.username && (
                <span className="text-sm text-rose-500">
                  Password enter a valid email.
                </span>
              )}
            </div>
            <div className="flex flex-col gap-1 md:gap-2">
              <div className="flex justify-between">
                <p className="font-medium">Password</p>
                <div
                  onClick={() => setShowPassword((previous) => !previous)}
                  className="flex gap-2 items-center text-tttDefault cursor-pointer"
                >
                  {showPassword ? (
                    <>
                      <EyeOffIcon className="h-5 w-5" />
                      Hide
                    </>
                  ) : (
                    <>
                      <EyeIcon className="h-5 w-5" />
                      Show
                    </>
                  )}
                </div>
              </div>
              <Controller
                render={({ field }) => (
                  <input
                    {...field}
                    id="password"
                    data-testid="password-input"
                    placeholder="Enter Password"
                    type={showPassword ? 'text' : 'password'}
                    className="border border-gray-300 rounded-md px-3 py-2 w-full"
                    onCopy={(event) => event.preventDefault()}
                    onCut={(event) => event.preventDefault()}
                    onPaste={(event) => event.preventDefault()}
                  />
                )}
                name="password"
                control={control}
                rules={{
                  required: true,
                  minLength: 8,
                  maxLength: 100,
                  // pattern: passwordPattern, // NOTE: older passwords might not contain special characters
                }}
              />
              {errors.password && (
                <span className="text-sm text-rose-500">
                  Password enter a valid password.
                </span>
              )}
            </div>
            <div className="flex items-center text-center justify-between">
              <Link
                to={'/password-reset'}
                className="w-full text-sm text-tttDefault hover:underline"
              >
                Forgot Password?
              </Link>
            </div>
            <button
              type="submit"
              disabled={isLoading || !isValid}
              className="
                flex items-center justify-center w-full bg-tttDefault text-white font-medium py-2 xl:py-3 px-2 xl:px-4 rounded-full
                hover:shadow-md transition-all duration-500 ease-in-out hover:bg-opacity-95
                disabled:opacity-50 disabled:cursor-not-allowed
              "
            >
              {isLoading ? <ClipLoader color={'#fff'} size={'24'} /> : 'Log In'}
            </button>
            {isError && (
              <div className="text-sm text-center text-rose-500">
                {(
                  (error as FetchBaseQueryError)?.data as any
                )?.message.includes('Wrong email or password') ? (
                  <>
                    Incorrect email or password. Please double check your
                    entered details.
                  </>
                ) : (
                  <>Server error, please try again later.</>
                )}
              </div>
            )}
          </form>
          <p className="w-full md:mt-auto text-center text-sm text-gray-500">
            Don't have an account?{' '}
            <Link to={'/sign-up'} className=" text-tttDefault hover:underline">
              <span className="text-tttDefault">Sign Up</span>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
