import { push } from 'redux-first-history';
import { CheckIcon, ChevronLeftIcon, XIcon } from '@heroicons/react/outline';

import {
  useFinalizeQuizSubmissionMutation,
  useSubmitPracticeMutation,
} from '../services/apiSlice';
import { handleError } from '../services/ErrorService';
import {
  goToProblem,
  hideFinishModal,
  selectIsPractice,
  selectIsTimeUp,
  selectRemainingTime,
  selectSkippedProblems,
} from '../features/quiz/quizSlice';
import { store, useAppSelector } from '../stores/AppStore';
import Button from './Button';

export default function Finish() {
  const currentQuiz = useAppSelector((state) => state.quiz.selectedQuiz);
  const isPractice = useAppSelector(selectIsPractice);
  const remainingSeconds = useAppSelector(selectRemainingTime);
  const resultId = useAppSelector((state) => state.quiz.resultId);
  const skippedProblems = useAppSelector(selectSkippedProblems);
  const solvedProblems = useAppSelector((state) => state.quiz.solvedProblems);
  const timeIsUp = useAppSelector(selectIsTimeUp);
  const [finalizeQuizSubmission, { isLoading: isFinalizingQuizSubmission }] =
    useFinalizeQuizSubmissionMutation();
  const [submitPractice, { isLoading: isSubmittingPractice }] =
    useSubmitPracticeMutation();

  const handleSubmitPractice = async () => {
    if (!currentQuiz || !Array.isArray(solvedProblems)) {
      return;
    }
    await submitPractice({
      trackId: currentQuiz.trackSerialNum,
      quiz: {
        id: currentQuiz.id,
        problems: solvedProblems.map((p) => {
          const indexToKey = ['A', 'B', 'C', 'D', 'E'];
          // find shuffled problem
          const problem = currentQuiz.problems.find(
            (currentProblem) => currentProblem.id === p.id,
          );
          // find answer text in shuffled answers
          const answerText =
            problem?.answers[indexToKey.indexOf(p.givenAnswerKey ?? '')];
          return {
            problemId: p.id,
            answerText,
          };
        }),
      },
      answerDuration: currentQuiz.timeLimit - remainingSeconds,
    });
    store.dispatch(
      push(`/review/${currentQuiz.trackSerialNum}/${currentQuiz.id}`),
    );
  };

  if (!currentQuiz || !Array.isArray(solvedProblems)) {
    handleError(new Error('Quiz data is missing at Finish'));
    return <></>; // FIXME
  }

  return (
    <div className="grid col-span-4 grid-cols-3 items-center gap-3">
      <div className="grid col-span-3 justify-items-center ">
        {skippedProblems.length > 0 ? (
          <p className="pb-5 text-lg">You skipped the following problems:</p>
        ) : (
          <div className="flex">
            <CheckIcon className="h-8 w-8 text-tttDefault pr-2" />
            <p className="text-lg">You didn't skip any problems</p>
          </div>
        )}
      </div>
      {timeIsUp ? (
        <></>
      ) : (
        <button
          disabled={isFinalizingQuizSubmission || isSubmittingPractice}
          className="col-start-1 col-span-1 justify-items-start items-center"
        >
          <ChevronLeftIcon
            className="h-8 w-8 text-tttDefault"
            onClick={() => store.dispatch(hideFinishModal())}
          />
        </button>
      )}
      <div className="grid col-start-2 col-span-1 justify-items-center items-center">
        {skippedProblems.map((problem, index) => (
          <div
            key={`skippedproblems-${problem.id}`}
            className="grid text-lg justify-items-center items-center w-full"
          >
            {timeIsUp ? (
              <div className="grid justify-items-start items-center w-full">
                <div className="flex">
                  <XIcon className="h-7 w-7 text-tttDefault pr-2" />
                  Problem {index + 1}
                </div>
              </div>
            ) : (
              <div className="grid justify-items-start items-center text-start w-full">
                <div className="flex">
                  <button
                    onClick={() => store.dispatch(goToProblem(problem.num))}
                    disabled={
                      isFinalizingQuizSubmission || isSubmittingPractice
                    }
                    className="flex"
                  >
                    <XIcon className="h-7 w-7 text-tttDefault pr-2" />
                    Problem {problem.num + 1}
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
        {skippedProblems.length > 0 && remainingSeconds > 60 && !isPractice && (
          <p className="py-5 text-tttDefault">
            Tip: You still have time to go back and try to solve a skipped
            problem.
          </p>
        )}
      </div>
      <div className="grid col-start-1 md:col-start-3 col-span-3 md:col-span-1 justify-items-center md:justify-items-end items-center md:items-end h-full">
        {isPractice ? (
          <Button
            action={handleSubmitPractice}
            bgColor={'bg-blue-300'}
            bgColorHover={'bg-blue-400'}
            buttonText={'View answers'}
            disabled={isSubmittingPractice}
          />
        ) : (
          <Button
            action={async () => {
              await finalizeQuizSubmission({
                resultId: resultId ?? '',
                quiz: {
                  id: currentQuiz.id,
                  problems: solvedProblems.map((p) => ({
                    problemId: p.id,
                    answerKey: p.givenAnswerKey,
                  })),
                },
              });
              store.dispatch(
                push(`/review/${currentQuiz.trackSerialNum}/${currentQuiz.id}`),
              );
            }}
            bgColor={'bg-blue-300'}
            bgColorHover={'bg-blue-400'}
            buttonText={'Submit'}
            disabled={isFinalizingQuizSubmission}
          />
        )}
      </div>
    </div>
  );
}
