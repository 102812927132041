export function PrivacyPolicy() {
  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-semibold text-center mb-8">
        Privacy Policy
      </h1>
      <p className="text-lg mb-8">
        <strong>Effective Date</strong>: 6/24/24
      </p>
      <div className="pl-5 grid gap-4">
        <div className="text-lg font-bold">1. Introduction</div>
        <p className="">
          Welcome to Fill the Math GAPS. This Privacy Policy explains how we
          collect, use, disclose, and safeguard your information when you use
          our software and services. By accessing or using our services, you
          agree to this Privacy Policy. If you do not agree with the terms of
          this Privacy Policy, please do not use our services.
        </p>
        <div className="text-lg font-bold">2. Information We Collect</div>
        <div className="text-md font-bold">2.1 Personal Information</div>
        <p className="">
          We may collect personal information that you provide to us when you
          register for an account, use our services, or communicate with us.
          This information may include:
        </p>
        <ul className="list-disc pl-5">
          <li>Name</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Payment information</li>
          <li>Student’s name, grade level, and learning preferences</li>
        </ul>
        <div className="text-md font-bold">2.2 Usage Data</div>
        <p className="">
          We may collect information about how you access and use our services.
          This may include:
        </p>
        <ul className="list-disc pl-5">
          <li>Device information (e.g., IP address, browser type)</li>
          <li>Log information (e.g., usage times, accessed pages)</li>
          <li>Cookies and similar tracking technologies</li>
        </ul>
        <div className="text-md font-bold">2.3 Academic Data</div>
        <p className="">
          To provide our services, we may collect data related to your child’s
          performance, including:
        </p>
        <ul className="list-disc pl-5">
          <li>Quiz results</li>
          <li>Learning progress and performance</li>
          <li>Areas of strength and improvement</li>
        </ul>
        <div className="text-lg font-bold">3. How We Use Your Information</div>
        <p className="">
          We may use the information we collect for various purposes, including:
        </p>
        <ul className="list-disc pl-5">
          <li>To provide and maintain our services</li>
          <li>To personalize your experience</li>
          <li>To process transactions</li>
          <li>To communicate with you about updates, offers, and promotions</li>
          <li>To monitor and analyze usage and trends</li>
          <li>To improve our services and develop new features</li>
          <li>To protect our services and users</li>
        </ul>
        <div className="text-lg font-bold">
          4. Disclosure of Your Information
        </div>
        <p className="">
          We may share your information in the following circumstances:
        </p>
        <ul className="list-disc pl-5">
          <li>
            <strong>With Service Providers</strong>: We may share your
            information with third-party service providers who perform services
            on our behalf.
          </li>
          <li>
            <strong>For Legal Reasons</strong>: We may disclose your information
            if required to do so by law or in response to valid requests by
            public authorities.
          </li>
          <li>
            <strong>Business Transfers</strong>: In the event of a merger,
            acquisition, or sale of all or a portion of our assets, your
            information may be transferred.
          </li>
        </ul>
        <div className="text-lg font-bold">5. Data Security</div>
        <p className="">
          We use administrative, technical, and physical security measures to
          help protect your personal information. However, no method of
          transmission over the internet or electronic storage is completely
          secure, and we cannot guarantee its absolute security.
        </p>
        <div className="text-lg font-bold">6. Your Choices</div>
        <p className="">
          You have certain rights regarding your personal information, including
          the right to:
        </p>
        <ul className="list-disc pl-5">
          <li>Access, correct, or delete your personal information</li>
          <li>Opt-out of receiving marketing communications</li>
          <li>Withdraw consent where processing is based on consent</li>
        </ul>
        <p className="">
          To exercise these rights, please contact us at help@thinktankprep.com.
        </p>
        <div className="text-lg font-bold">7. Children's Privacy</div>
        <p className="">
          Our services are intended for use by children under the supervision of
          parents or guardians. We do not knowingly collect personal information
          from children under the age of 13 without verifiable parental consent.
          If we become aware that we have collected personal information from a
          child under 13 without parental consent, we will take steps to delete
          such information.
        </p>
        <div className="text-lg font-bold">
          8. Changes to This Privacy Policy
        </div>
        <p className="">
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on our website.
          You are advised to review this Privacy Policy periodically for any
          changes.
        </p>
        <div className="text-lg font-bold">9. Contact Us</div>
        <p className="">
          If you have any questions about this Privacy Policy, please contact us
          at:
        </p>
        <ul className="list-disc pl-5">
          <li>Email: help@thinktankprep.com</li>
          <li>Address: 510 W Northwest Highway, Arlington Heights, IL 60004</li>
        </ul>
      </div>
    </div>
  );
}
