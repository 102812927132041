import { push } from 'redux-first-history';
import Modal from '../components/Modal';
import { store, useAppSelector } from '../stores/AppStore';
import { formatSeconds } from '../util';
import { leaveQuiz, selectIsCompleted } from '../features/quiz/quizSlice';
import { getDefaultPagePath } from '../features/user/userSlice';
import { PiClockLight } from 'react-icons/pi';
import GapsSummary from './GapsSummary';
import QuizSkillResults from '../components/Assignment/QuizSkillResults';
import { StudentQuizStatSkillResult } from '../types';

const QuizResult = ({
  actionButtonText,
  setShowModal,
}: {
  actionButtonText: string;
  setShowModal: (state: boolean) => void;
}) => {
  const resultStats = useAppSelector((state) => state.quiz.resultStats);
  const currentQuiz = useAppSelector((state) => state.quiz.selectedQuiz);
  const defaultPagePath = useAppSelector(getDefaultPagePath);
  const isCompleted = useAppSelector(selectIsCompleted);

  return (
    <div className="justify-items-center items-center">
      <Modal
        buttonText={actionButtonText}
        buttonAction={() => {
          if (isCompleted) {
            store.dispatch(push(defaultPagePath));
            store.dispatch(leaveQuiz());
          } else {
            setShowModal(false);
          }
        }}
      >
        <GapsSummary
          subjectResults={resultStats.gapsResults ?? []}
          mainScore={resultStats.quizResult ?? 0}
          mainScoreLabel={`${currentQuiz?.id} Result`}
          hideHeader={true}
        />

        {resultStats.answerDuration && (
          <div className="flex items-center my-4">
            <PiClockLight className="w-5 h-5 text-tttDefault mr-2" />
            Completion Time:&nbsp;
            <p className="text-gray-700">
              {formatSeconds(resultStats.answerDuration)}
            </p>
          </div>
        )}

        {resultStats.skills && (
          <div className="bg-white rounded-xl p-8">
            <QuizSkillResults
              skillResults={
                resultStats.skills as unknown as StudentQuizStatSkillResult[]
              }
            />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default QuizResult;
