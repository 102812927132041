import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { FromSchema } from 'json-schema-to-ts';
import { toast } from 'react-hot-toast';
import { shuffle } from 'lodash';

import { ROLES } from '../../constants';
import {
  quizSchema,
  QuizStatus,
  SolvedProblem,
  solvedProblemSchema,
} from '../../types';
import { RootState } from '../../stores/AppStore';
import { getPersistedState } from '../../services/LocalStorageService';
import { handleError } from '../../services/ErrorService';
import { apiSlice } from '../../services/apiSlice';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

const quizStateSchema = {
  type: 'object',
  properties: {
    dueAt: { type: 'string', format: 'date-time' },
    isPractice: { type: 'boolean' },
    solvedProblems: { type: 'array', items: solvedProblemSchema },
    progress: { type: 'number' },
    resultId: { type: 'string' },
    resultStats: {
      type: 'object',
      properties: {
        answerDuration: { type: 'number' },
        gapsResults: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              subject: { type: 'string' },
              result: { type: 'number' },
            },
            required: ['subject', 'result'],
          },
        },
        quizResult: { type: 'number' },
        skills: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              skill: { type: 'string' },
              skillName: { type: 'string' },
              result: { type: 'number' },
              frequency: { type: 'string' },
            },
            required: ['result'],
          },
        },
      },
    },
    selectedProblemIndex: { type: 'number' },
    selectedQuiz: quizSchema,
    status: { enum: Object.values(QuizStatus) },
    trackSerialNum: { type: 'string' },
    tutorials: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          url: { type: 'string' },
          problemNum: { type: 'string' },
          problemId: { type: 'string' },
        },
        required: ['url', 'problemNum'],
      },
    },
  },
  required: ['progress', 'resultStats', 'status'],
  additionalProperties: false,
} as const;

type QuizState = FromSchema<typeof quizStateSchema>;

const emptyState = {
  dueAt: undefined,
  isPractice: undefined,
  progress: 0,
  resultStats: {},
  selectedProblemIndex: undefined,
  selectedQuiz: undefined,
  status: QuizStatus.inactive,
  trackSerialNum: undefined,
} as QuizState;

const initialState =
  getPersistedState<QuizState>('quiz', quizStateSchema) ?? emptyState;

const skipErrorCounter: Record<string, number> = {};

const watchReviewSkipErrors = (quizId: string, problemIndex: number) => {
  const errorCounterIndex = `${quizId}_${problemIndex}`;
  if (skipErrorCounter[errorCounterIndex]) {
    skipErrorCounter[errorCounterIndex]++;
  } else {
    skipErrorCounter[errorCounterIndex] = 1;
  }
  if (skipErrorCounter[errorCounterIndex] % 3 === 0) {
    handleError(new Error('Student tried to skip review many times'), {
      tags: {
        quizId,
        problemIndex: String(problemIndex),
        skipCount: String(skipErrorCounter[errorCounterIndex]),
      },
    });
  }
};

export const quizSlice = createSlice({
  name: 'quiz',
  initialState: { ...initialState },
  reducers: {
    resetQuizState: () => {
      return emptyState;
    },
    hideFinishModal: (state) => {
      state.status = QuizStatus.inProgress;
    },
    overviewBeforeSubmit: (state) => {
      if (state.status !== QuizStatus.inProgress) {
        handleError(
          new Error(
            `Couldn't overview before submit as Quiz wasn't in progress`,
          ),
        );
        return;
      }
      state.progress = 100;
      state.status = QuizStatus.overviewBeforeSubmit;
    },
    startReviewingQuiz: (state) => {
      if (state.status !== QuizStatus.overviewBeforeSubmit) {
        handleError(
          new Error(`Couldn't start Quiz review as there was no Quiz solved`),
        );
        return;
      }
      state.status = QuizStatus.underReview;
    },
    setVideoWatched: (state) => {
      if (state.status !== QuizStatus.underReview) {
        handleError(
          new Error(
            `Couldn't set video watched as the quiz wasn't under review`,
          ),
        );
        return;
      }
      if (!state.solvedProblems) {
        handleError(
          new Error(
            `Couldn't set video watched as the answers are unavailable`,
          ),
        );
        return;
      }
      state.solvedProblems[state.selectedProblemIndex ?? 0].isVideoWatched =
        true;
    },
    setVideoWatchedForPastProblems: (state, { payload }) => {
      if (state.status !== QuizStatus.underReview) {
        handleError(
          new Error(
            `Couldn't set video watched as the quiz wasn't under review`,
          ),
        );
        return;
      }
      if (!state.solvedProblems) {
        handleError(
          new Error(
            `Couldn't set video watched as the answers are unavailable`,
          ),
        );
        return;
      }

      const { index } = payload;
      if (index < 0 || index >= state.solvedProblems.length) {
        handleError(
          new Error(
            `Invalid index while setting video watched for past problems: ${index}`,
          ),
        );
        return;
      }
      for (const problem of state.solvedProblems.slice(0, index + 1)) {
        problem.isVideoWatched = true;
      }
    },
    completeReview: (state) => {
      if (state.status !== QuizStatus.underReview) {
        handleError(
          new Error(
            `Couldn't complete Quiz review as the quiz wasn't under review`,
          ),
        );
        return;
      }
      if (!Array.isArray(state.solvedProblems)) {
        handleError(
          new Error(
            `Couldn't complete Quiz review as there were no problems reviewed`,
          ),
        );
        return;
      }
      if (
        !state.isPractice &&
        state.selectedProblemIndex &&
        state.status === QuizStatus.underReview
      ) {
        const currentSolvedProblem = Array.isArray(state.solvedProblems)
          ? state.solvedProblems[state.selectedProblemIndex]
          : undefined;
        if (
          !currentSolvedProblem?.isVideoWatched &&
          !currentSolvedProblem?.isStudentCorrect
        ) {
          watchReviewSkipErrors(
            state.selectedQuiz?.id ?? 'unknown',
            state.selectedProblemIndex,
          );
          toast.error(
            'You need to watch the tutorial video before proceeding. You can speed up the playback if needed.',
          );
          throw new Error('Video not watched.');
        }
      }
      return {
        ...state,
        status: QuizStatus.completed,
      };
    },
    leaveQuiz: (state) => {
      if (state.status === QuizStatus.underReview) {
        handleError(
          new Error(`Couldn't leave Quiz as review wasn't completed`),
        );
        return;
      }
      return { ...emptyState };
    },
    selectAnswer: (state, action: PayloadAction<string>) => {
      if (
        !Array.isArray(state.solvedProblems) ||
        state.selectedProblemIndex === undefined
      ) {
        handleError(
          new Error(`Couldn't select answer as problem was not selected`),
        );
        return;
      }
      const problemWithAnswer =
        state.solvedProblems[state.selectedProblemIndex];
      if (!problemWithAnswer) {
        handleError(
          new Error(`Couldn't select answer as problem was not found`),
        );
        return;
      }
      problemWithAnswer.givenAnswerKey = action.payload;
    },
    loadPreviousProblem: (state) => {
      if (!state.selectedQuiz || !state.selectedProblemIndex) {
        handleError(new Error(`Can't load previous problem`));
        return;
      }
      state.selectedProblemIndex -= 1;
      if (state.status !== QuizStatus.underReview) {
        state.status = QuizStatus.inProgress;
      }
    },
    loadNextProblem: (state) => {
      if (
        !state.selectedQuiz ||
        state.selectedProblemIndex === undefined ||
        state.selectedProblemIndex === state.selectedQuiz.problems.length - 1
      ) {
        handleError(new Error(`Couldn't load next problem`));
        return;
      }
      if (!state.isPractice && state.status === QuizStatus.underReview) {
        const currentSolvedProblem = Array.isArray(state.solvedProblems)
          ? state.solvedProblems[state.selectedProblemIndex]
          : undefined;
        if (
          !currentSolvedProblem?.isVideoWatched &&
          !currentSolvedProblem?.isStudentCorrect
        ) {
          watchReviewSkipErrors(
            state.selectedQuiz.id,
            state.selectedProblemIndex,
          );
          toast.error(
            'You need to watch the tutorial video before proceeding. You can speed up the playback if needed.',
          );
          return;
        }
      }
      state.selectedProblemIndex += 1;
      state.progress = Math.max(
        state.progress,
        (100 * state.selectedProblemIndex) /
          (state.solvedProblems?.length ?? 1),
      );
    },
    goToProblem: (state, { payload }) => {
      if (
        !state.selectedQuiz ||
        typeof payload !== 'number' ||
        !Number.isInteger(payload) ||
        payload < 0 ||
        payload >= state.selectedQuiz.problems.length
      ) {
        handleError(new Error(`Couldn't load selected problem`), {
          tags: {
            action: 'goToProblem',
            payload,
          },
        });
      }
      state.selectedProblemIndex = payload;
      if (state.status !== QuizStatus.underReview) {
        state.status = QuizStatus.inProgress;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        apiSlice.endpoints.startQuizSolving.matchFulfilled,
        (state, { payload }) => {
          if (!payload._id || !payload.problems) {
            handleError(
              new Error('Invalid server response at startQuizSolving'),
              {
                tags: {
                  response: JSON.stringify(payload),
                },
              },
            );
            return;
          }
          return {
            resultId: payload._id,
            isPractice: false,
            dueAt: payload.dueAt,
            solvedProblems: payload.problems.map((p, number_) => ({
              id: p.id,
              num: number_,
              givenAnswerKey: p.givenAnswerKey,
            })),
            progress: 0,
            resultStats: {},
            selectedQuiz: payload,
            selectedProblemIndex: 0,
            status: QuizStatus.inProgress,
            trackSerialNum: payload.trackSerialNum,
          } as QuizState;
        },
      )
      .addMatcher(
        apiSlice.endpoints.startQuizPracticing.matchFulfilled,
        (state, { payload }) => {
          let { quiz } = payload;
          const { isStudentOrThinker } = payload;
          if (!quiz._id || !quiz.problems) {
            handleError(
              new Error('Invalid server response at startQuizPracticing'),
              {
                tags: {
                  response: JSON.stringify(quiz),
                },
              },
            );
            return;
          }

          // unchanged solvedProblems (initialization)
          let solvedProblems: SolvedProblem[] = quiz.problems.map(
            (p, number_) => ({
              id: p.id,
              num: number_,
            }),
          );

          if (isStudentOrThinker) {
            // shuffle problems
            const shuffledProblems = shuffle(quiz.problems);
            solvedProblems = shuffledProblems.map((p, number_) => ({
              id: p.id,
              num: number_,
            }));
            quiz = {
              ...quiz,
              problems: shuffledProblems.map((p) => ({
                ...p,
                // shuffle answers
                answers: shuffle(p.answers),
              })),
            };
          }

          return {
            isPractice: true,
            dueAt: dayjs()
              .add(quiz.timeLimit ?? 1800, 'seconds')
              .toISOString(),
            solvedProblems,
            progress: 0,
            resultStats: {},
            selectedQuiz: quiz,
            selectedProblemIndex: 0,
            status: QuizStatus.inProgress,
            trackSerialNum: quiz.trackSerialNum,
          } as QuizState;
        },
      )
      .addMatcher(
        apiSlice.endpoints.submitPractice.matchFulfilled,
        (state, { payload }) => {
          const { quiz, isStudentOrThinker } = payload;
          if (
            typeof quiz.quizResult === undefined ||
            !quiz.gapsResults ||
            !quiz.skills ||
            !quiz.tutorials
          ) {
            handleError(
              new Error('Invalid server response at submitPractice'),
              {
                tags: {
                  response: JSON.stringify(payload),
                },
              },
            );
            return;
          }

          // unchanged solvedProblems
          let solvedProblems: SolvedProblem[] = state.solvedProblems
            ? state.solvedProblems.map((p, index) => ({
                ...p,
                correctAnswerKey: quiz.problems[index].correctAnswerKey,
                isStudentCorrect: quiz.problems[index].isStudentCorrect,
              }))
            : [];

          if (isStudentOrThinker) {
            // map correctAnswerText to shuffled correctAnswerKey
            solvedProblems = state.solvedProblems
              ? state.solvedProblems.map((solvedProblem) => {
                  const problem = quiz.problems.find(
                    (prob) => prob.problemId === solvedProblem.id,
                  );
                  const shuffledProblem = state.selectedQuiz?.problems.find(
                    (p) => p.id === solvedProblem.id,
                  );
                  if (!problem || !shuffledProblem) {
                    handleError(
                      new Error(
                        `Couldn't find (shuffled) problem for quiz problem ${solvedProblem.id}`,
                      ),
                    );
                    return solvedProblem;
                  }

                  const indexToKey = ['A', 'B', 'C', 'D', 'E'];
                  // map aswerText to shuffled answerKey
                  const correctAnswerKey =
                    indexToKey[
                      shuffledProblem.answers.indexOf(problem.correctAnswerText)
                    ];

                  return {
                    ...solvedProblem,
                    correctAnswerKey: correctAnswerKey,
                    isStudentCorrect: problem?.isStudentCorrect,
                  };
                })
              : [];
          }

          return {
            ...state,
            resultStats: {
              gapsResults: quiz.gapsResults,
              quizResult: quiz.quizResult,
              skills: quiz.skills,
              answerDuration: quiz.answerDuration,
            },
            selectedProblemIndex: 0,
            solvedProblems,
            status: QuizStatus.underReview,
            tutorials: quiz.tutorials,
          };
        },
      )
      .addMatcher(
        apiSlice.endpoints.finalizeQuizSubmission.matchFulfilled,
        (state, { payload }) => {
          if (
            typeof payload.quizResult === undefined ||
            typeof payload.answerDuration === undefined
          ) {
            handleError(
              new Error('Invalid server response at finalizeQuizSubmission'),
              {
                tags: {
                  response: JSON.stringify(payload),
                },
              },
            );
            return;
          }
          return {
            ...state,
            resultStats: {
              ...state.resultStats,
              quizResult: payload.quizResult,
              answerDuration: payload.answerDuration,
            },
            selectedProblemIndex: 0,
            solvedProblems: state.solvedProblems
              ? state.solvedProblems.map((p, index) => ({
                  ...p,
                  correctAnswerKey:
                    payload.quiz.problems[index].correctAnswerKey,
                  isStudentCorrect:
                    payload.quiz.problems[index].isStudentCorrect,
                }))
              : [],
            status: QuizStatus.underReview,
          };
        },
      )
      .addMatcher(
        apiSlice.endpoints.getTutorialsForProblems.matchFulfilled,
        (state, { payload }) => {
          return {
            ...state,
            tutorials: payload,
          };
        },
      )
      .addMatcher(
        apiSlice.endpoints.getSubjectResultsForResultId.matchFulfilled,
        (state, { payload }) => ({
          ...state,
          resultStats: {
            ...state.resultStats,
            gapsResults: payload,
          },
        }),
      )
      .addMatcher(
        apiSlice.endpoints.getSkillResultsForResultId.matchFulfilled,
        (state, { payload }) => ({
          ...state,
          resultStats: {
            ...state.resultStats,
            skills: payload,
          },
        }),
      )
      .addMatcher(
        apiSlice.endpoints.getResult.matchFulfilled,
        (state, { payload }) => {
          if (!payload) {
            return;
          }
          return {
            ...state,
            resultId: payload._id,
            resultStats: {
              ...state.resultStats,
              quizResult: payload.quizResult,
              answerDuration: payload.answerDuration,
            },
            solvedProblems: payload.quiz.problems.map((p, index) => ({
              correctAnswerKey: p.correctAnswerKey,
              isStudentCorrect: p.isStudentCorrect,
              givenAnswerKey: p.answerKey,
              id: p.problemId,
              num: index,
            })),
            selectedProblemIndex: 0,
            status: QuizStatus.underReview,
            trackSerialNum: payload.track.serialNum,
          };
        },
      )
      .addMatcher(
        apiSlice.endpoints.getQuizDetails.matchFulfilled,
        (state, { payload }) => {
          return {
            ...state,
            selectedQuiz: payload,
          };
        },
      );
  },
});

export const {
  completeReview,
  hideFinishModal,
  goToProblem,
  leaveQuiz,
  loadNextProblem,
  loadPreviousProblem,
  resetQuizState,
  overviewBeforeSubmit,
  selectAnswer,
  setVideoWatched,
  setVideoWatchedForPastProblems,
  startReviewingQuiz,
} = quizSlice.actions;

export const selectActiveQuizPath = (state: RootState) => {
  if (state.quiz.isPractice) {
    return;
  }
  if (
    !state.quiz.selectedQuiz?.id ||
    [QuizStatus.inactive, QuizStatus.completed].includes(state.quiz.status)
  ) {
    return;
  }
  return state.quiz.status === QuizStatus.underReview
    ? `/review/${state.quiz.selectedQuiz.trackSerialNum}/${state.quiz.selectedQuiz.id}`
    : `/quiz/${state.quiz.selectedQuiz.trackSerialNum}/${
        state.quiz.selectedQuiz.id
      }/${state.quiz.isPractice ? 'practice' : 'solve'}`;
};

export const selectCurrentProblemIndex = (state: RootState) =>
  state.quiz.selectedProblemIndex;

export const selectCurrentProblem = (state: RootState) =>
  state.quiz.selectedQuiz &&
  Array.isArray(state.quiz.selectedQuiz?.problems) &&
  typeof state.quiz.selectedProblemIndex === 'number'
    ? state.quiz.selectedQuiz.problems[state.quiz.selectedProblemIndex]
    : undefined;

export const selectCurrentSolvedProblem = (state: RootState) =>
  state.quiz.selectedQuiz &&
  Array.isArray(state.quiz.solvedProblems) &&
  typeof state.quiz.selectedProblemIndex === 'number'
    ? state.quiz.solvedProblems[state.quiz.selectedProblemIndex]
    : undefined;

export const selectIsPractice = (state: RootState) => state.quiz.isPractice;

export const selectIsInactive = (state: RootState) =>
  state.quiz.status === QuizStatus.inactive;

export const selectIsInProgress = (state: RootState) =>
  state.quiz.status === QuizStatus.inProgress;

export const selectIsOverviewBeforeSubmit = (state: RootState) =>
  state.quiz.status === QuizStatus.overviewBeforeSubmit;

export const selectIsUnderReview = (state: RootState) =>
  state.quiz.status === QuizStatus.underReview;

export const selectIsCompleted = (state: RootState) =>
  state.quiz.status === QuizStatus.completed;

export const selectIsLastProblem = (state: RootState) =>
  Array.isArray(state.quiz.selectedQuiz?.problems) &&
  state.quiz.selectedProblemIndex ===
    (state.quiz.selectedQuiz?.problems.length as number) - 1;

export const selectQuizId = (state: RootState) => state.quiz.selectedQuiz?.id;

export const selectRemainingTime = (state: RootState) =>
  dayjs(state.quiz.dueAt).diff(dayjs(), 'seconds');

export const selectTimeDueAt = (state: RootState) => state.quiz.dueAt;

export const selectIsTimeUp = (state: RootState) =>
  dayjs(state.quiz.dueAt).isBefore(dayjs());

export const selectGivenAnswerKey = (state: RootState) => {
  const selectedProblem =
    state.quiz.solvedProblems &&
    state.quiz.selectedProblemIndex !== undefined &&
    state.quiz.solvedProblems[state.quiz.selectedProblemIndex];
  if (!selectedProblem) {
    handleError(
      new Error('selectedProblem is undefined at selectGivenAnswerKey'),
    );
    return true;
  }
  return selectedProblem.givenAnswerKey;
};

export const selectSkippedProblems = (state: RootState) => {
  if (!state.quiz.solvedProblems) {
    handleError(
      new Error('solvedProblems is undefined at selectSkippedProblems'),
    );
    return [];
  }
  return state.quiz.solvedProblems.filter((p) => !p.givenAnswerKey);
};

export const selectIsAllowedToProceedInReview = (state: RootState) => {
  const selectedProblem =
    state.quiz.solvedProblems &&
    state.quiz.selectedProblemIndex !== undefined &&
    state.quiz.solvedProblems[state.quiz.selectedProblemIndex];
  if (!selectedProblem) {
    handleError(
      new Error(
        'selectedProblem is undefined at selectIsAllowedToProceedInReview',
      ),
    );
    return true;
  }
  return (
    state.user.user?.roles.includes(ROLES.teacher) ||
    state.quiz.isPractice ||
    selectedProblem.isStudentCorrect ||
    selectedProblem.isVideoWatched
  );
};

export default quizSlice.reducer;
