/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC, useEffect, useState } from 'react';

import { FilterType, thinkerChartEmptyText } from '../constants';

import {
  apiSlice,
  useGetStudentOrThinkerSummaryDataQuery,
  useGetThinkerResultsForMentorQuery,
} from '../services/apiSlice';
import { store, useAppSelector } from '../stores/AppStore';
import { setShowWelcomeVideo } from '../features/global/globalSlice';

import Filter, { FilterValue } from '../components/Filter';
import { Loading, LoadingBlock } from '../components/Loading';
import StudentDrillDown from '../partials/StudentDrillDown';
import Welcome from '../components/Welcome';

const ThinkerScores: FC = () => {
  const [selectedThinker, setSelectedThinker] = useState<string>();

  const didWatchWelcomeVideo = useAppSelector(
    (state) => state.global.metrics.didWatchWelcomeVideo,
  );
  const showWelcomeVideo = useAppSelector(
    (state) => state.global.settings.show.welcomeVideo,
  );

  const getThinkerDocument = (thinkerName?: string) => {
    if (!thinkerResults) {
      return;
    }
    return thinkerResults?.find((thinker) => thinker.name === thinkerName);
  };
  const {
    data: thinkerResults,
    isLoading: isLoadingThinkerResults,
    isFetching: isFetchingThinkerResults,
    isError: isErrorThinkerResults,
  } = useGetThinkerResultsForMentorQuery();
  const {
    data: thinkerResult,
    isFetching: isFetchingThinkerResult,
    isLoading: isLoadingThinkerResult,
  } = useGetStudentOrThinkerSummaryDataQuery(
    {
      studentId: getThinkerDocument(selectedThinker)?.student ?? '',
      shouldFetchTrack: true,
    },
    {
      skip:
        !thinkerResults || getThinkerDocument(selectedThinker) === undefined,
    },
  );

  const prefetchThinkerResult = apiSlice.usePrefetch(
    'getStudentOrThinkerSummaryData',
  );

  const thinkerFilterOptions = thinkerResults?.map((r) => ({
    label: r.name,
    value: r.name,
  }));

  useEffect(() => {
    if (didWatchWelcomeVideo) {
      // NOTE: initially we keep the Welcome video always visible
      store.dispatch(setShowWelcomeVideo(true));
    } else {
      store.dispatch(setShowWelcomeVideo(true));
    }
  }, []);

  useEffect(() => {
    if (!thinkerResults) {
      return;
    }
    setSelectedThinker(() => thinkerResults[0].name);
  }, [thinkerResults]);

  if (isLoadingThinkerResults) {
    return <LoadingBlock />;
  } else if (isErrorThinkerResults) {
    throw new Error('Failed to load class students data');
  }

  return (
    <div className="grid gap-2 md:gap-4">
      {showWelcomeVideo && (
        <div className="m-4 md:m-0">
          <Welcome />
        </div>
      )}
      <div
        className="
          relative
          grid gap-4 p-4 md:p-6
          w-full h-full items-center 
        bg-gray-100 rounded-xl
        "
      >
        <div className="absolute right-4">
          {isFetchingThinkerResults && <Loading />}
        </div>
        <h1 className="text-3xl font-medium">Your Thinkers</h1>
        <Filter
          filterType={'thinker' as FilterType}
          options={thinkerFilterOptions ?? []}
          selected={
            selectedThinker
              ? {
                  label: selectedThinker,
                  value: selectedThinker,
                }
              : undefined
          }
          onSelect={(selectedFilter: FilterValue) => {
            setSelectedThinker(() => selectedFilter.label);
          }}
          onHover={(selectedFilter: FilterValue) => {
            prefetchThinkerResult({
              studentId:
                getThinkerDocument(selectedFilter.label)?.student ?? '',
              shouldFetchTrack: true,
            });
          }}
        />
        {selectedThinker && (
          <StudentDrillDown
            shouldScrollIntoView={false}
            isLoading={isLoadingThinkerResult || isFetchingThinkerResult}
            isOpen={!!thinkerResult}
            result={thinkerResult}
            chartEmptyText={thinkerChartEmptyText}
          />
        )}
      </div>
    </div>
  );
};

export default ThinkerScores;
