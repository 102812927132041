import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { InformationCircleIcon } from '@heroicons/react/outline';

import { store, useAppSelector } from '../../stores/AppStore';
import {
  selectMentor,
  selectThinkers,
  setThinkers,
} from '../../features/signUp/signUpSlice';
import { useLazyCheckEmailExistsQuery } from '../../services/apiSlice';
import { emailPattern } from '../../constants';

import ProgressBar from './ProgressBar';
import { FieldError } from '../ui/field-error';

interface ThinkerDetailsProperties {
  onNextStep: () => void;
  onPreviousStep: () => void;
  licenseIndex: number;
}

const ThinkerDetails = ({
  onNextStep,
  onPreviousStep,
  licenseIndex,
}: ThinkerDetailsProperties) => {
  const [emailError, setEmailError] = useState('');
  const thinkers = useAppSelector(selectThinkers);
  const mentor = useAppSelector(selectMentor);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<{
    firstName: string;
    lastName: string;
    email: string;
    trackId: string;
  }>({
    mode: 'all',
    defaultValues: thinkers[licenseIndex],
  });
  const [
    checkEmailExists,
    { error: isCheckingEmailError, isFetching: isCheckingEmailExists },
  ] = useLazyCheckEmailExistsQuery();

  const handleContinue = async (thinker: {
    firstName: string;
    lastName: string;
    email: string;
    trackId: string;
  }) => {
    const result = await checkEmailExists({ email: thinker.email });
    if (result.data === true || result.error) {
      setEmailError('Email already exists.');
      return;
    }
    const emailUsed = thinkers.some(
      (t, index) => t.email === thinker.email && index !== licenseIndex,
    );
    if (emailUsed) {
      setEmailError('Email already used for another thinker.');
      return;
    }
    if (mentor.email === thinker.email) {
      setEmailError('Email already used for mentor.');
      return;
    }
    const newThinkers = [...thinkers];
    thinker.trackId = '664f73cc78a827930c0f142c'; // FIXME remove after track selection is reinstated
    newThinkers[licenseIndex] = thinker;
    store.dispatch(setThinkers(newThinkers));
    onNextStep();
  };

  useEffect(() => {
    reset(thinkers[licenseIndex]);
  }, [licenseIndex]);

  return (
    <form
      data-testid="subscription-details"
      onSubmit={handleSubmit(handleContinue)}
      className="flex flex-col w-full h-full"
    >
      <div className="flex flex-col gap-4 md:gap-6 w-full md:w-[70%] lg:w-[60%] mx-auto">
        <h1 className="text-3xl md:text-4xl font-medium w-full">
          Details of Thinker {thinkers.length > 1 ? licenseIndex + 1 : ''}
        </h1>
        <p className="text-gray-500">
          {thinkers.length > 1 ? 'Each' : 'A'} subscription is assigned to one
          Thinker. Your Thinker will receive a welcome email with a link to
          set-up their account and begin the Fill the Math GAPS journey.
        </p>
        <div
          data-testid="thinker-details"
          className="flex flex-col gap-3 md:gap-6"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6 md:gap-y-2 items-center">
            <div className="flex flex-col gap-1 w-full">
              <label
                htmlFor="firstName"
                className="text-sm md:text-base font-medium text-gray-700"
              >
                First Name
              </label>
              <Controller
                render={({ field }) => (
                  <input
                    {...field}
                    id="firstName"
                    className="border border-gray-300 rounded-md px-3 py-2 w-full"
                    placeholder="Enter First Name"
                  />
                )}
                name="firstName"
                control={control}
                rules={{ required: true, minLength: 2, maxLength: 50 }}
              />
              <FieldError
                errors={errors}
                field="firstName"
                fieldName="First name"
              />
            </div>
            <div className="flex flex-col gap-1 w-full">
              <label
                htmlFor="lastName"
                className="text-sm md:text-base font-medium text-gray-700"
              >
                Last Name
              </label>
              <Controller
                render={({ field }) => (
                  <input
                    {...field}
                    id="lastName"
                    className="border border-gray-300 rounded-md px-3 py-2 w-full"
                    placeholder="Enter Last Name"
                  />
                )}
                name="lastName"
                control={control}
                rules={{ required: true, minLength: 2, maxLength: 50 }}
              />
              <FieldError
                errors={errors}
                field="lastName"
                fieldName="Last name"
              />
            </div>
            <div className="flex flex-col gap-1 w-full">
              <label
                htmlFor="email"
                className="text-sm md:text-base font-medium text-gray-700"
              >
                Email
              </label>
              <Controller
                render={({ field }) => (
                  <input
                    {...field}
                    className="border border-gray-300 rounded-md px-3 py-2 w-full"
                    placeholder="Enter Email"
                    id="email"
                    onChange={(event) => {
                      field.onChange(event.target.value);
                      setEmailError('');
                    }}
                  />
                )}
                name="email"
                control={control}
                rules={{ required: true, pattern: emailPattern }}
              />
              <FieldError errors={errors} field="email" fieldName="Email" />
              {emailError && (
                <p className="text-sm text-rose-600">{emailError}</p>
              )}
            </div>
            <p className={'flex items-start gap-2 text-sm text-gray-500'}>
              <InformationCircleIcon className="h-5 w-5 md:h-8 md:w-8 text-tttDefault" />
              No subscription-related or marketing emails will be sent to this
              email.
            </p>
          </div>
          <div className="flex flex-row bg-gray-100 rounded-xl p-4">
            <div>
              <h1 className="font-medium text-lg mb-1 md:mb-2">Thinker</h1>
              <p className="text-sm text-gray-500">
                A young explorer on a journey of discovery, mastering math
                skills through practice and curiosity. A thinker uses their
                knowledge and creativity to solve problems and overcome
                challenges.
              </p>
            </div>
            <img
              src="/images/icons/bulb.svg"
              className="w-20 h-20 md:w-28 md:h-28 p-2 md:p-4 self-center"
              alt="Bulb Icon"
            />
          </div>
          {Object.keys(errors).length > 0 && (
            <p data-testid="error-message" className="text-sm text-rose-600">
              Please enter valid details for Thinker #{licenseIndex + 1}.
            </p>
          )}
        </div>
      </div>

      <ProgressBar />

      {/* Action Buttons */}
      <div className="flex gap-2 pt-4 md:pt-6 w-full justify-between items-center">
        <button
          data-testid={`back-button`}
          type="button"
          className="
              px-10 py-2 w-max rounded-full border font-medium
              transition-all duration-300 ease-in-out
              hover:shadow hover:outline hover:outline-tttDefault hover:outline-[1px]
              disabled:opacity-50 disabled:cursor-not-allowed
            "
          disabled={licenseIndex === 0}
          onClick={onPreviousStep}
          title={
            licenseIndex === 0
              ? `You can't step back after a successful payment`
              : ''
          }
        >
          Back
        </button>
        <button
          data-testid={`continue-button`}
          type="submit"
          disabled={
            !isValid ||
            isCheckingEmailExists ||
            !!isCheckingEmailError ||
            !!emailError
          }
          className="
            px-10 py-2 w-max rounded-full
            font-medium text-white bg-tttDefault
            transition-all duration-300 ease-in-out
            hover:shadow hover:bg-opacity-90
            disabled:opacity-50 disabled:cursor-not-allowed
          "
        >
          Next
        </button>
      </div>
    </form>
  );
};

export default ThinkerDetails;
