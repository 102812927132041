import Vimeo from '@u-wave/react-vimeo';
import { Link } from 'react-router-dom';

import { Tutorial } from '../types';
import { Subject } from '../constants';

export default function TutorialCard({ problemNum, skills, url }: Tutorial) {
  return (
    <div
      key={problemNum}
      className={`grid bg-gray-100 rounded-xl w-full min-h-[200px] overflow-hidden text-left`}
    >
      <Vimeo video={url} controls={true} speed={true} responsive />
      <div className="p-2 font-medium self-end">{`${problemNum}`}</div>
      <div className="min-h-[50px]">
        {skills.map((s) => (
          <div
            key={s.skill}
            className="py-1 px-2 text-xs text-gray-600 inline-block"
          >
            <Link
              to={`/tutorials/${
                s.subject === 'Algebra 1' ? Subject.algebra : s.subject
              }?skills=${s.skill}`}
            >{`${s.skill}`}</Link>
          </div>
        ))}
      </div>
    </div>
  );
}
