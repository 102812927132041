import { cloneElement, useEffect, useRef } from 'react';

interface InfiniteScrollProperties {
  loadMore: () => void;
  hasMore: boolean;
  isLoading: boolean;
  loader: React.ReactElement;
  className?: string;
  children: React.ReactNode;
}
const InfiniteScroll = ({
  loadMore,
  hasMore,
  isLoading,
  loader: loaderProperty,
  className,
  children,
}: InfiniteScrollProperties) => {
  const observerTarget = useRef(null);
  const loader = cloneElement(loaderProperty, { ref: observerTarget });

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting && hasMore && !isLoading) {
        loadMore();
      }
    });

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, hasMore, isLoading, loadMore]);

  return (
    <div className={className}>
      {children}
      {loader}
    </div>
  );
};

export default InfiniteScroll;
