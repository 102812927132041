import { ReactElement } from 'react';
import {
  ChartBarIcon,
  ClipboardListIcon,
  LightBulbIcon,
  QuestionMarkCircleIcon,
  TemplateIcon,
  TrendingUpIcon,
  UserGroupIcon,
  DocumentSearchIcon,
  DocumentTextIcon,
} from '@heroicons/react/outline';
import { FaBullseye } from 'react-icons/fa';
import { HiOutlineWrenchScrewdriver } from 'react-icons/hi2';
import { observer } from 'mobx-react-lite';

import { useStores } from '../stores/RootStore';
import { SidebarItem } from './SidebarItem';
import { FeatureFlag, ROLES, isFeatureFlagOverridden } from '../constants';
import ColorLegend from './ColorLegend';
import { useAppSelector } from '../stores/AppStore';
import { selectHasOneOfTheRoles } from '../features/user/userSlice';

interface MenuItem {
  path: string;
  title: string;
  icon: ReactElement;
}

function BaseSidebar() {
  const {
    rootStore: { uiStore },
  } = useStores();

  const user = useAppSelector((state) => state.user.user);
  const isStudentOrThinker = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.student, ROLES.thinker]),
  );
  const isTeacher = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.teacher]),
  );
  const isSchoolAdmin = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.schooladmin]),
  );
  const isMentor = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.mentor]),
  );
  const isOwner = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.owner]),
  );
  const isOwnerOrSysAdmin = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.owner, ROLES.sysadmin]),
  );

  let menuItems: MenuItem[] = [];

  const commonMenuItems = [
    {
      path: '/tutorials',
      title: 'Tutorials by Subject',
      icon: <LightBulbIcon className="h-8 w-8 text-tttDefault" />,
    },
    {
      path: '/help',
      title: 'Help',
      icon: <QuestionMarkCircleIcon className="h-8 w-8 text-tttDefault" />,
    },
  ];

  if (isStudentOrThinker) {
    menuItems.push(
      {
        path: '/summary',
        title: 'Summary',
        icon: <TemplateIcon className="h-8 w-8 text-tttDefault" />,
      },
      {
        path: '/assignments',
        // path: hasRole(ROLES.student)
        //   ? '/assignments'
        //   : '/thinker/assignments',
        title: 'Assignments',
        icon: <ClipboardListIcon className="h-8 w-8 text-tttDefault" />,
      },
    );
  }

  if (isTeacher) {
    menuItems.push(
      {
        path: `/teacher/student-scores`,
        title: 'Student Scores',
        icon: <TemplateIcon className="h-8 w-8 text-tttDefault" />,
      },
      {
        path: `/teacher/gaps-scores`,
        title: 'Period GAPS Scores',
        icon: <TrendingUpIcon className="h-8 w-8 text-tttDefault" />,
      },
    );
  }

  if (isSchoolAdmin) {
    menuItems.push(
      {
        path: `/schooladmin/teacher-scores`,
        title: 'Teacher Scores',
        icon: <TemplateIcon className="h-8 w-8 text-tttDefault" />,
      },
      {
        path: `/schooladmin/gaps-scores`,
        title: 'School GAPS Scores',
        icon: <TrendingUpIcon className="h-8 w-8 text-tttDefault" />,
      },
    );
    if (!menuItems.some((element) => element.title === 'Assignments')) {
    }
  }

  if (isMentor) {
    menuItems.push({
      path: `/mentor/thinkers`,
      title: 'Dashboard',
      icon: <TemplateIcon className="h-8 w-8 text-tttDefault" />,
    });
  }

  if (isSchoolAdmin) {
    menuItems.push({
      path: '/schooladmin/assignments',
      title: 'Assignments',
      icon: <ClipboardListIcon className="h-8 w-8 text-tttDefault" />,
    });
  } else if (isTeacher) {
    menuItems.push({
      path: '/teacher/assignments',
      title: 'Assignments',
      icon: <ClipboardListIcon className="h-8 w-8 text-tttDefault" />,
    });
  } else if (isMentor) {
    menuItems.push({
      path: '/mentor/assignments',
      title: 'Assignments',
      icon: <ClipboardListIcon className="h-8 w-8 text-tttDefault" />,
    });
  }
  if (isOwnerOrSysAdmin) {
    menuItems.push(
      {
        path: '/users',
        title: 'Users List',
        icon: <UserGroupIcon className="h-8 w-8 text-tttDefault" />,
      },
      {
        path: '/kpi-dashboard',
        title: 'Key Performance Indicators',
        icon: <ChartBarIcon className="h-8 w-8 text-tttDefault" />,
      },
    );
  }
  if (isOwner) {
    menuItems.push(
      {
        path: '/problem-content',
        title: 'Problem Content',
        icon: <DocumentSearchIcon className="h-8 w-8 text-tttDefault" />,
      },
      {
        path: '/targeted-practice/manage',
        title: 'Targeted Practice Manager',
        icon: (
          <HiOutlineWrenchScrewdriver className="h-8 w-8 text-tttDefault" />
        ),
      },
      {
        path: '/system-logs',
        title: 'System Logs',
        icon: <DocumentTextIcon className="h-8 w-8 text-tttDefault" />,
      },
    );
  }
  if (
    isTeacher &&
    // TODO: remove this check after targeted practice is fully implemented
    (isFeatureFlagOverridden(FeatureFlag.TargetedPractice) ||
      user?.featureFlags?.includes(FeatureFlag.TargetedPractice))
  ) {
    menuItems.push({
      path: '/targeted-practice/assign',
      title: 'Targeted Practice',
      icon: <FaBullseye className="h-8 w-8 text-tttDefault" />,
    });
  }

  menuItems = [...menuItems, ...commonMenuItems];

  return (
    <div
      className={`
        fixed lg:static z-50 top-16 md:top-20 
        flex flex-col gap-4
        w-full md:w-3/4 lg:w-max h-full
        p-4 lg:p-0
        bg-blue-100 lg:bg-transparent
        md:rounded-tr-xl
        transform ${
          uiStore.isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } lg:translate-x-0 transition-transform duration-300 ease-in-out`}
    >
      <div className={`bg-blue-100 lg:p-3 rounded-xl`}>
        {menuItems.map((item) => {
          return (
            <div key={`sidebarelement-${item.title}`} className="box">
              <SidebarItem
                path={item.path}
                title={item.title}
                children={item.icon}
              />
            </div>
          );
        })}
      </div>
      <ColorLegend />
    </div>
  );
}

const Sidebar = observer(BaseSidebar);
export default Sidebar;
