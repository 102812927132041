import { FromSchema } from 'json-schema-to-ts';
import { PointStyle } from 'chart.js';

import { ActionButton } from './components/List';
import { ImportStatus, ROLES, Subject } from './constants';

const importUserSchema = {
  type: 'object',
  properties: {
    _id: { type: 'string' },
    firstName: { type: 'string' },
    lastName: { type: 'string' },
  },
  required: ['firstName', 'lastName'],
  additionalProperties: false,
} as const;

export type ImportUser = FromSchema<typeof importUserSchema>;

const importStatsSchema = {
  type: 'object',
  properties: {
    classes: { type: 'number' },
    problems: { type: 'number' },
    quizschedules: { type: 'number' },
    skills: { type: 'number' },
    tracks: { type: 'number' },
    tutorials: { type: 'number' },
    users: { type: 'number' },
  },
  required: [
    'classes',
    'problems',
    'quizschedules',
    'skills',
    'tracks',
    'tutorials',
    'users',
  ],
  additionalProperties: false,
} as const;

export type ImportStats = FromSchema<typeof importStatsSchema>;

export const importDocumentSchema = {
  type: 'object',
  properties: {
    _id: { type: 'string' },
    status: { enum: Object.values(ImportStatus) },
    progress: { type: 'number' },
    stats: importStatsSchema,
    warnings: { type: 'array', items: { type: 'string' } },
    importErrors: { type: 'array', items: { type: 'string' } },
    user: importUserSchema,
    createdAt: { type: 'string' },
    updatedAt: { type: 'string' },
  },
  required: ['_id', 'status', 'progress', 'stats', 'warnings', 'importErrors'],
  additionalProperties: false,
} as const;

export type ImportDocument = FromSchema<typeof importDocumentSchema>;

export const importStateSchema = {
  type: 'object',
  properties: {
    uploadPercentage: { type: 'number' },
    importYear: { type: 'string' },
    details: importDocumentSchema,
  },
  required: ['uploadPercentage', 'importYear', 'details'],
  additionalProperties: false,
} as const;

export type ImportState = FromSchema<typeof importStateSchema>;

export type Skill = {
  _id: string;
  skill: string;
  skillNum: string;
  subject: string;
};

export type CircularBarType =
  | 'schooladmin class'
  | 'teacher class'
  | 'skill'
  | 'subject'
  | 'student'
  | 'teacher';

export type ChartData = {
  labels: any[];
  datasets: {
    label?: string;
    type?: any;
    data: (number | undefined)[];
    borderColor?: string;
    borderDash?: [number, number]; // [length, space]
    backgroundColor?: string;
    pointStyle?: PointStyle;
    trendlineLinear?: {
      color: string;
      lineStyle: 'solid' | 'dotted';
      width: number;
      projection?: boolean;
      style: any; // NOTE: necessary for type compliance
    };
  }[];
};

export type ClassTrackDetail = {
  _id: string;
  year: string;
  track: string;
  serialNum: string;
  quizzes: Omit<Quiz, 'startDate'>[];
  createdAt: string;
  updatedAt: string;
};

export type CompletedQuiz = Omit<Quiz, 'problems'> & {
  problems: string[];
  quizId?: string;
  result: number;
  date: Date;
  index: number;
  canStart?: boolean;
  link: string;
  actionButtons?: ActionButton[];
};

export type CurrentQuiz = Omit<Quiz, 'problems'> & {
  problems: string[];
  canStart?: boolean;
  isCompleted?: boolean;
  dueDate?: string;
  isOverdue?: boolean;
  index: number;
};

// export type CurrentQuizProblem = ProblemDocument & {
//   videoWatched?: boolean;
// };

// export type DisplayedQuizProblem = CurrentQuizProblem;

export type QuizWithInfo = Omit<Quiz, 'problems'> & {
  problems: string[];
  quizId?: string;
  info?: string;
  actionButtons?: any;
  index: number;
  canStart?: boolean;
  link: string;
};

export declare function emptyFunction(): void;

export interface ExtendedTrack extends TrackDocument {
  // problemsForCurrentQuiz?: CurrentQuizProblem[];
  currentSchedules: QuizSchedule[];
  pastSchedules: QuizSchedule[];
  currentAssignments: CurrentQuiz[];
  completedAssignments?: CompletedQuiz[];
  missedAssignments?: QuizWithInfo[];
  pastAssignments?: QuizWithInfo[];
  upcomingAssignments?: QuizWithInfo[];
  upcomingSchedules?: QuizSchedule[];
  studentsMissed?: StudentMissed[];
}

export type OngoingQuiz = {
  isOngoing: boolean;
  isPractice: boolean;
  isUnderReview: boolean;
  isReviewComplete: boolean;
  id?: string;
  givenAnswers: GivenAnswer[];
  selectedAnswer?: SelectedAnswer;
};

export type GivenAnswer = {
  problemId: string;
  displayedProblemNumber: number;
  answerKey: string;
};

export type SelectedAnswer = Omit<GivenAnswer, 'displayedProblemNumber'>;

export type Problem = {
  answerKey: string;
  correctAnswerKey?: string;
  isStudentCorrect: boolean;
  problemId: string;
};

export type SubmittedQuiz = {
  id: string;
  problems: {
    problemId: string;
    answerKey?: string;
    answerText?: string;
    isStudentCorrect?: boolean;
  }[];
};

export const problemDocumentSchema = {
  type: 'object',
  properties: {
    _id: { type: 'string' },
    id: { type: 'string' },
    subject: { enum: Object.values(Subject) },
    skills: { type: 'array', items: { type: 'string' } },
    difficulty: { type: 'string' },
    question: { type: 'string' },
    additionalImages: { type: 'array', items: {} },
    answers: { type: 'array', items: { type: 'string' } },
    correctAnswerKey: { type: 'string' },
    tutorial: {},
    givenAnswerKey: { type: 'string' },
    createdAt: { type: 'string' },
    // tutorial: {
    //   type: 'object',
    //   properties: {
    //     url: { type: 'string' },
    //   },
    //   required: ['url'],
    // },
  },
  required: [
    'id',
    'subject',
    'skills',
    'question',
    'additionalImages',
    'answers',
    'tutorial',
  ],
  additionalProperties: false,
} as const;

export type ProblemDocument = FromSchema<typeof problemDocumentSchema>;

export const targetedPracticeProblemDocumentSchema = {
  type: 'object',
  properties: {
    _id: { type: 'string' },
    id: { type: 'string' },
    subject: { enum: Object.values(Subject) },
    skills: { type: 'array', items: { type: 'string' } },
    difficulty: { type: 'string' },
    question: { type: 'string' },
    additionalImages: { type: 'array', items: {} },
    answers: { type: 'array', items: { type: 'string' } },
    correctAnswerKey: { type: 'string' },
    tutorial: {},
    givenAnswerKey: { type: 'string' },
    createdAt: { type: 'string' },
    writtenSolution: { type: 'string' },
    draft: { type: 'boolean' },
  },
  required: [
    'id',
    'subject',
    'skills',
    'question',
    'additionalImages',
    'answers',
    'tutorial',
    'writtenSolution',
  ],
  additionalProperties: false,
} as const;

export type TargetedPracticeProblemDocument = FromSchema<
  typeof targetedPracticeProblemDocumentSchema
>;

export type ResultCount = {
  quiz: string;
  resultCount: number;
};

export type ResultQuiz = {
  id: string;
  isReview?: boolean;
  problems: Problem[];
};

const reviewedProblemSchema = {
  type: 'object',
  properties: {
    id: { type: 'string' },
    num: { type: 'number' },
  },
  required: ['id', 'num'],
  additionalProperties: false,
} as const;

export type ReviewedProblem = FromSchema<typeof reviewedProblemSchema>;

const reviewedQuizSchema = {
  type: 'object',
  properties: {
    id: { type: 'string' },
    didFinishReview: { type: 'boolean' },
    progress: reviewedProblemSchema,
  },
  required: ['id', 'didFinishReview'],
  additionalProperties: false,
} as const;

export type ReviewedQuiz = FromSchema<typeof reviewedQuizSchema>;

export type StudentMissed = {
  name: string;
  missedAssignments: [{ quizId: string }];
};

export type SchoolTrack = Pick<TrackDocument, '_id' | 'track' | 'year'> & {
  classes?: (ClassDocument & { teacherName?: string })[];
};

export type ProblemSkills = {
  _id: string;
  problemId: string;
  skills: string[];
};

export type Tag = {
  value: string;
  count: number;
  key: string;
  color: string;
  props?: any;
};

export const quizSchema = {
  type: 'object',
  properties: {
    _id: { type: 'string' },
    id: { type: 'string' },
    problems: { type: 'array', items: problemDocumentSchema },
    startDate: { type: 'string' },
    timeLimit: { type: 'number' },
    dueAt: { type: 'string', format: 'date-time' },
    checkpointTestUrl: { type: 'string' },
    checkpointTestAnswerKeysUrl: { type: 'string' },
    isReview: { type: 'boolean' },
    trackSerialNum: { type: 'string' },
    workbookUrl: { type: 'string' },
  },
  required: ['_id', 'id', 'problems', 'timeLimit', 'trackSerialNum'],
  additionalProperties: false,
} as const;

export type Quiz = FromSchema<typeof quizSchema>;

const trackSchema = {
  type: 'object',
  properties: {
    program: { type: 'string' },
    serialNum: { type: 'string' },
    track: { type: 'string' },
  },
  required: ['program', 'serialNum', 'track'],
  additionalProperties: false,
} as const;

export type Track = FromSchema<typeof trackSchema>;

const trackDocumentSchema = {
  type: 'object',
  properties: {
    _id: { type: 'string' },
    serialNum: { type: 'string' },
    track: { type: 'string' },
    year: { type: 'string' },
    quizzes: { type: 'array', items: quizSchema },
  },
  required: ['_id', 'serialNum', 'track'],
  additionalProperties: false,
} as const;

export type TrackDocument = FromSchema<typeof trackDocumentSchema>;

const classDocumentSchema = {
  type: 'object',
  properties: {
    _id: { type: 'string' },
    class: { type: 'string' },
    school: { type: 'string' },
    students: { type: 'array', items: { type: 'string' } },
    teacher: { type: 'string' },
    teacherEmail: { type: 'string' },
    trackDetails: trackDocumentSchema,
    tracks: { type: 'array', items: trackSchema },
    year: { type: 'string' },
  },
  required: [
    '_id',
    'class',
    'school',
    'students',
    'teacher',
    'teacherEmail',
    'trackDetails',
    'tracks',
    'year',
  ],
  additionalProperties: false,
} as const;

export type ClassDocument = FromSchema<typeof classDocumentSchema>;

export type Tutorial = {
  _id: string;
  url: string;
  skills: Skill[];
  subjects: string[];
  problemNum: string;
  problemId: string;
};

export const taskSchema = {
  type: 'object',
  properties: {
    id: { type: 'string' },
    activity: { type: 'string' },
    name: { type: 'string' },
    description: { type: 'string' },
    icon: { type: 'string' },
    link: { type: 'string' },
    status: { type: 'string' },
    quiz: { type: 'string' },
  },
  required: ['id', 'activity', 'name', 'description', 'icon', 'link', 'status'],
  additionalProperties: false,
} as const;

export const userDocumentSchema = {
  type: 'object',
  properties: {
    _id: { type: 'string' },
    email: { type: 'string' },
    studentId: { type: 'string' },
    firstName: { type: 'string' },
    lastName: { type: 'string' },
    roles: { type: 'array', items: { enum: Object.values(ROLES) } },
    attendsClass: { type: 'string' },
    teachesClasses: { type: 'array', items: { type: 'string' } },
    classesTaught: {
      type: 'array',
      items: classDocumentSchema,
    },
    adminOfSchool: { type: 'string' },
    changedPasswordAt: { type: 'string', format: 'date-time' },
    reviewedQuizzes: {
      type: 'array',
      items: reviewedQuizSchema,
    },
    school: { type: 'string' },
    lastQuiz: reviewedQuizSchema,
    followsTracks: { type: 'array', items: trackSchema },
    featureFlags: { type: 'array', items: { type: 'string' } },
    tasks: {
      type: 'array',
      items: taskSchema,
    },
  },
  required: ['_id', 'email', 'firstName', 'lastName', 'roles'],
  additionalProperties: false,
} as const;

export type UserDocument = FromSchema<typeof userDocumentSchema>;

export type QuizSchedule = {
  school: string;
  year: string;
  track: Track | string;
  displayedTrack: string;
  classes?: string[];
  quiz: string;
  startDate: Date;
  dueDate: Date;
  index: number;
  isReview?: boolean;
};
interface ExtendedErrorParameters {
  name: string;
  message: string;
  stack?: string;
}

export class ExtendedError extends Error {
  constructor({ name, message, stack }: ExtendedErrorParameters) {
    super();
    super.name = name;
    super.message = message;
    super.stack = stack;
  }
}

export interface TrackOptionValue {
  track: string;
  serialNum: string;
}

export interface SelectOption {
  label: string;
  value: string | number | TrackOptionValue;
}

export interface AddThinkerFormData {
  firstName: string;
  lastName: string;
  email: string;
  track: string;
  trackSerialNum: string;
  mentor: string;
  scheduleFrequency: number;
}

export interface LoginCredentials {
  username: string;
  password: string;
}

export interface PasswordChangeParameters {
  currentPassword: string;
  newPassword: string;
  confirm: string;
}

export interface PasswordResetParameters {
  password: string;
  confirm: string;
}

export interface LoginResponse {
  access_token: string;
  clientVersion: string;
}

export enum AppState {
  init = 'init',
  justAuthenticated = 'justAuthenticated',
  loaded = 'loaded',
}

export interface Assignment {
  _id: string;
  id: string;
  index: number;
  problems: string[];
  timeLimit: number; // seconds
  isReview?: boolean;
}

export interface CompletedAssignment extends Assignment {
  date: string;
  result: number; // percentage
}

export type MentorAssignment = Omit<Quiz, 'problems'> & {
  problems: string[];
  track: {
    track: string;
    serialNum: string;
  };
};

export interface StudentTrackWithSchedule {
  _id: string;
  completedAssignments: CompletedAssignment[];
  currentAssignments: Assignment[];
  currentSchedules: QuizSchedule[];
  missedAssignments: Assignment[];
  pastSchedules: QuizSchedule[];
  serialNum: string;
  track: string;
}

export interface TutorialsResponse {
  tutorials: Tutorial[];
  hasMore: boolean;
  total: number;
}

export enum QuizStatus {
  inactive = 'inactive',
  inProgress = 'inProgress',
  overviewBeforeSubmit = 'overviewBeforeSubmit',
  underReview = 'underReview',
  completed = 'completed',
}

export const solvedProblemSchema = {
  type: 'object',
  properties: {
    id: { type: 'string' },
    num: { type: 'number' },
    givenAnswerKey: { type: 'string' },
    correctAnswerKey: { type: 'string' },
    isStudentCorrect: { type: 'boolean' },
    isVideoWatched: { type: 'boolean' },
  },
  required: ['id', 'num'],
  additionalProperties: false,
} as const;

export type SolvedProblem = FromSchema<typeof solvedProblemSchema>;

export interface QuizAnswerPayload {
  _id: string;
  class?: string;
  track?: string;
  quiz: {
    id?: string;
    isReview?: boolean;
    problems: SelectedAnswer[];
  };
}

export interface SubmitPracticeResponse {
  problems: {
    problemId: string;
    answerKey?: string;
    correctAnswerKey: string;
    correctAnswerText: string;
    isStudentCorrect: boolean;
  }[];
  quizResult: number;
  gapsResults: {
    subject: string;
    result: number;
  }[];
  skills: {
    skill: string;
    result: number;
  }[];
  tutorials: {
    url: string;
    problemNum: string;
    problemId: string;
  }[];
  answerDuration: number;
}

export type QuizResultStats = {
  studentId: string;
  name: string;
  isAnswerCorrect: Array<boolean | null>;
  skillsList?: string[][];
  score: number;
  practiceResult?: {
    answerDuration: number;
    quizResult: number;
  };
  answerDuration: number;
  didReviewQuiz?: boolean;
  problemNums?: string[];
  pushedThroughBy?: string;
};

export type StudentScores = {
  name: string;
  score?: number;
  isAnswerCorrect?: boolean[];
  didReviewQuiz: boolean | undefined;
  pushedThroughBy?: string;
  problemNums?: string[];
};

export type QuizStats = {
  gapsResults: SubjectResult[];
  skillResults: SkillResult[];
  quizScores?: QuizScores;
  quiz?: Quiz;
};

export type StudentQuizStats = {
  skillResults: StudentQuizStatSkillResult[];
  result?: ResultDocument;
  problems?: ResultQuizProblem[];
  numOfPractices?: number;
  lastPracticeScore?: number;
  didFinishReview?: boolean;
};

export type StudentQuizStatSkillResult = {
  skill: string;
  skillName: string;
  result: number;
  frequency: 1 | 2 | 3;
};

export type ProblemTotalScore = {
  problemId: string;
  question?: string;
  result: number;
  skills?: string[];
};

type QuizScores = {
  quizId: string;
  problemTotals: ProblemTotalScore[];
  scores: QuizResultStats[] | StudentScores[];
  averageAnswerDuration?: number;
  averageScore?: number;
};

// General quiz stat types

export type ResultDocument = {
  _id: string;
  startedAt: string;
  dueAt: string;
  completedAt: string;
  answerDuration: number;
  student: string;
  class?: string;
  track: {
    _id: string;
    serialNum: string;
  };
  year: string;
  isCompleted: boolean;
  isBenchmark: boolean;
  isReview?: boolean;
  quiz: {
    id: string;
    problems: ResultQuizProblem[];
  };
  quizResult: number;
  subjectResults: SubjectResultFractional[];
  skillResults: SkillResultFractional[];
};

export type SubjectResultFractional = {
  subject: string;
  numerator: number;
  denominator: number;
};

export type SubjectResult = {
  subject: string;
  result: number;
};

export type SkillResultFractional = {
  skill: string;
  skillName: string;
  numerator: number;
  denominator: number;
};

export type SkillResult = {
  skill?: string;
  result: number;
};

export type ExtendedSkillResult = {
  skill: string;
  skillNum: string;
  description: string;
  subject: string;
  overallResult: number;
  reviewResult?: number;
  quizResults: QuizResult[];
};

export type ResultQuizProblem = {
  problemId: string;
  answerKey?: string;
  correctAnswerKey?: string;
  isStudentCorrect?: boolean;
  updatedAt?: string;
};

export type QuizResult = {
  quiz: string;
  result: number;
};

export type ExtendedSubjectResult = {
  subject: string;
  overallResult: number;
  reviewResult: number;
  quizResults: QuizResult[];
};

// Student stat types

export type StudentResultOverview = {
  student: {
    _id: string;
    name: string;
  };
  overallResult: number;
  quizResults: StudentQuizResult[];
  reviewResult: number | null;
  subjectResults: SubjectResult[]; // NOTE: kept for backwards compatibility
  lastSubmittedResult?: ResultDocument;
  track?: string;
};

export type StudentResult = {
  student: string;
  class?: string; // student only
  teacher?: string; // student only
  school?: string; // student only
  mentor?: string; // thinker only
  track: string;
  overallResult: number;
  reviewResult: number | null;
  subjectResults: SubjectResultFractional[];
  skillResults: SkillResultFractional[];
  reviewSubjectResults: SubjectResultFractional[];
  reviewSkillResults: SkillResultFractional[];
};

export type ExtendedStudentResult = StudentResult & {
  name: string;
  quizResults: QuizResult[];
};

export type StudentQuizResult = {
  quiz: string;
  result: number;
  student: string;
  class?: string; // student only
  teacher?: string; // student only
  school?: string; // student only
  mentor?: string; // thinker only
  index?: string; // student only
  isReview?: boolean;
  track: string;
  subjectResults: SubjectResultFractional[];
  skillResults: SkillResultFractional[];
};

export type SubjectSkillResults = {
  subject: string;
  subjectResults: SubjectResult[];
  skillResults: ExtendedSkillResult[];
};

// Class stat types

export type ClassResultOverview = {
  overallResult?: number;
  reviewOverall?: number;
  quizResults?: ClassQuizResult[];
  submissionRates?: Record<string, number>;
};

export type ClassQuizResult = {
  class: string;
  teacher: string;
  school: string;
  track: string;
  subjectResults: SubjectResultFractional[];
  skillResults: SkillResultFractional[];
  quiz: string;
  result: number;
  index: string;
  isReview?: boolean;
};

export type ClassTotal = {
  _id: string;
  class: string;
  trackDetails: ClassTrackDetail;
  quizResults?: ClassQuizResult[];
  overallResult?: number;
  reviewResult?: number;
};

export type ClassSubjectResult = ClassResultOverview & {
  subjectResults: ExtendedSubjectResult[];
  skillResults: ExtendedSkillResult[];
};

// School stat types

export type SchoolResultOverview = {
  overallResult: number;
  reviewResult: number;
  quizResults: SchoolQuizResult[];
  submissionRates?: Record<string, number>;
};

export type SchoolQuizResult = {
  school: string;
  subjectResults: SubjectResultFractional[];
  skillResults: SkillResultFractional[];
  quiz: string;
  result: number;
  index: string;
  isReview?: boolean;
};

export type TeacherResult = {
  teacher: string;
  name: string;
  school: string;
  subjectResults: SubjectResultFractional[];
  skillResults: SkillResultFractional[];
  overallResult: number;
  reviewResult: number;
  reviewSubjectResults: SubjectResultFractional[];
  reviewSkillResults: SkillResultFractional[];
  quizResults: QuizResult[];
};

export interface GetUsersListParameters {
  skip?: number;
  limit?: number;
  sortBy?: string;
  desc?: boolean;
  search?: string;
}

export type TableDataResponse<T> = {
  hasMore?: boolean;
  total: number;
  items: T[];
};

export type UsersListItem = {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  studentId?: string;
  roles: string[];
  class?: string;
  classId?: string;
  school: string;
  lastQuiz?: ReviewedQuiz;
  lastSubmittedAvailableQuiz?: string;
  followsTracks?: string[];
  scheduleFrequency: number;
  mentor?: string;
  changedPasswordAt?: string;
  featureFlags?: string[];
};

export type TargetedPractice = {
  class: string;
  student: string;
  dueAt: Date;
  skill: string;
  quizName: string;
  problems: string[];
};

export type TargetedPracticeAssignListItem = {
  studentId: string;
  studentName: string;
  skillresults: ExtendedSkillResult[];
};

export type AssignedTargetedPracticeListItem = {
  studentId: string;
  studentName: string;
  targetedPractices: TargetedPractice[];
};

export type TargetedPracticeResult = {
  studentId: string;
  studentName: string;
  results: { quizName: string; result: number }[];
  missed: { quizName: string }[];
};

export type AssignTargetedPracticesPayload = {
  studentId: string;
  skill: string;
}[];

export interface SignupTrack {
  _id: string;
  track: string;
  serialNum: string;
}
