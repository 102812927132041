import { MathJax } from 'better-react-mathjax';
import dayjs from 'dayjs';

import { ProblemDocument, TargetedPracticeProblemDocument } from '../types';
import { PencilIcon } from '@heroicons/react/outline';

const ProblemContent = ({
  problem,
  editable = false,
  onEdit,
}: {
  problem: ProblemDocument | TargetedPracticeProblemDocument;
  editable?: boolean;
  onEdit?: () => void;
}) => {
  const answerKeys = ['A', 'B', 'C', 'D', 'E'];
  const hasAdditionalImages = (
    problem: ProblemDocument | TargetedPracticeProblemDocument,
  ) =>
    Array.isArray(problem.additionalImages) &&
    problem.additionalImages.length > 0;

  return (
    <div
      key={problem.id}
      className="w-full p-6 items-center gap-3 shadow-xl rounded-xl border-tttDefault border-[1px] transition-all duration-1000"
      data-testid={`problem-${problem.id}`}
    >
      <div className="flex justify-between items-center w-full pb-3">
        <div
          data-testid={`displayed-problem-number-${problem.id}`}
          className="w-max justify-items-start items-start text-white text-lg font-bold px-2 my-2 rounded-full bg-tttDefault"
        >
          {problem.id}
        </div>
        {editable && (
          <div className="flex items-center gap-2">
            <p className="text-gray-500">
              Created at: {dayjs(problem.createdAt).format('MM/DD/YYYY HH:mm')}
            </p>
            <button
              data-testid={`edit-button`}
              className="
              px-2 py-1 w-max h-max
              flex gap-1 items-center
              text-tttDefault bg-blue-200 rounded-xl
              hover:shadow-md transition-all duration-200 ease-in-out
            "
              onClick={typeof onEdit === 'function' ? onEdit : undefined}
            >
              Edit
              <PencilIcon className="h-5 w-5 self-center" />
            </button>
          </div>
        )}
      </div>
      <div className="grid col-start-2 col-span-2 justify-items-center items-center overflow-hidden">
        <div className="grid grid-cols-4 justify-items-center items-center gap-8 w-full">
          <div
            className="flex col-span-4 grid-rows-1 text-lg"
            data-testid="question"
          >
            <MathJax dynamic>{problem.question}</MathJax>
          </div>
          {hasAdditionalImages(problem) && (
            <div className="grid col-span-4 md:col-span-2 justify-items-center items-center">
              <img
                src={`data:image/png;base64,${problem.additionalImages[0]}`}
                alt=""
              />
            </div>
          )}
          <div
            className={`grid gap-2 ${
              hasAdditionalImages(problem)
                ? 'col-start-1 md:col-start-3 col-span-4 md:col-span-2'
                : 'col-span-4'
            }`}
          >
            {problem.answers.map((answer, index: number) => (
              <div
                key={`answer-${index}`}
                data-testid={`answer-${index}`}
                className={`grid grid-cols-[auto_1fr] gap-3 p-3 justify-items-start items-center text-lg`}
              >
                <p
                  data-testid={'answerKey'}
                  className={`font-bold w-10 h-10 text-center py-1 ${
                    problem.correctAnswerKey === answerKeys[index] &&
                    'border-2 rounded-full border-emerald-500'
                  }`}
                >
                  {answerKeys[index]}
                </p>
                <MathJax inline dynamic style={{ margin: '-1em 0' }}>
                  {answer}
                </MathJax>
              </div>
            ))}
          </div>
          {'writtenSolution' in problem && (
            <div
              className="flex flex-col col-span-4 w-full text-lg"
              data-testid="writtenSolution"
            >
              <label className="font-medium">Written Solution</label>
              <MathJax dynamic>{problem.writtenSolution}</MathJax>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProblemContent;
