import ReactDOM from 'react-dom/client';
import { configure } from 'mobx';
import { MathJaxContext } from 'better-react-mathjax';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { Provider } from 'react-redux';

import './index.css';
import App from './App';
import ErrorPage from './pages/Error';
import { history, store } from './stores/AppStore';
import { shouldExcludeFromSentry } from './util';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1,

  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllInputs: true,
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.replayCanvasIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  // guide: https://docs.sentry.io/platforms/javascript/configuration/sampling/#configuring-the-transaction-sample-rate
  tracesSampleRate: 0.2,
  beforeSend(event) {
    if (shouldExcludeFromSentry(event)) {
      // eslint-disable-next-line unicorn/no-null
      return null;
    }
    return event;
  },
});

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.querySelector('#root') as HTMLElement,
);

if (!process.env.REACT_APP_API_URL) {
  root.render(<ErrorPage />);
  throw new Error(
    'API base URL must be defined in the REACT_APP_API_URL env var!',
  );
}

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <Router history={history}>
        <MathJaxContext>
          <App />
        </MathJaxContext>
      </Router>
    </Provider>
  </QueryClientProvider>,
);

configure({
  enforceActions: 'always',
  computedRequiresReaction: true,
  reactionRequiresObservable: true,
  observableRequiresReaction: false,
});
