/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable unicorn/no-useless-undefined */
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import Collapsible from 'react-collapsible';
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronUpIcon,
} from '@heroicons/react/outline';

import { FilterType, ROLES } from '../constants';
import {
  ClassDocument,
  CompletedQuiz,
  CurrentQuiz,
  QuizWithInfo,
  SchoolTrack,
} from '../types';
import { downloadWorkbook, getCheckpointTestInfo, getQuiz } from '../util';

import { useAppSelector } from '../stores/AppStore';
import {
  useGetClassesTrackDetailsQuery,
  useGetQuizScoresQuery,
  useGetQuizTracksQuery,
  useGetSkillsForProblemsQuery,
  useGetTracksForSchoolQuery,
} from '../services/apiSlice';
import useTeacherAndSchooladminUpdatedAssignments from '../hooks/useTeacherAndSchooladminUpdatedAssignments';
import useSkillCloudData from '../hooks/useSkillCloudData';
import { selectHasOneOfTheRoles } from '../features/user/userSlice';

import List, { ActionButton } from '../components/List';
import { Loading, LoadingBlock } from '../components/Loading';
import Filter, { FilterValue } from '../components/Filter';
import SkillCloud from '../components/SkillCloud';
import { QuizScores } from '../partials/QuizScores';
import Button from '../components/Button';

const SchooladminTeacherAssignments: FC = () => {
  const [title, setTitle] = useState('');
  const [filter, setFilter] = useState<{
    track?: FilterValue<SchoolTrack>;
    class?: FilterValue<ClassDocument & { teacherName?: string }>;
    quiz?: CurrentQuiz | QuizWithInfo | CompletedQuiz;
    student?: { id: string; name: string };
  }>({});
  const [showScoreMatrix, setShowScoreMatrix] = useState(false);
  const user = useAppSelector((state) => state.user.user);
  if (!user) {
    return <></>;
  }
  const roles = user.roles;
  const isTeacher = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.teacher]),
  );
  const isSchoolAdmin = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.schooladmin]),
  );
  const hasBothRoles = isTeacher && isSchoolAdmin;
  const scollToScoresReference = useRef<HTMLDivElement>(null);
  const getTrackDocument = (trackId?: string) => {
    return tracks?.find((track) => track._id === trackId);
  };
  const { data: tracks, isFetching: isFetchingTracks } =
    useGetTracksForSchoolQuery(user.adminOfSchool ?? '', {
      skip: !user.adminOfSchool,
    });
  const { data: classes, isFetching: isFetchingClassesDetails } =
    useGetClassesTrackDetailsQuery(
      {
        classes: user.teachesClasses ?? [],
      },
      { skip: !isTeacher || !user.teachesClasses },
    );

  const { data: quizTrack, isFetching: isFetchingQuizTrack } =
    useGetQuizTracksQuery(
      {
        school: user.adminOfSchool,
        trackId: filter.track?.value?._id ?? undefined,
        classId: filter.class?.value?._id ?? undefined,
        isTeacher,
      },
      {
        skip: isTeacher ? !filter.class?.value?._id : !filter.track?.value?._id,
      },
    );
  const { data: quizStats, isFetching: isFetchingQuizStats } =
    useGetQuizScoresQuery(
      {
        school: user.adminOfSchool,
        trackId: filter.track?.value?._id ?? undefined,
        classId: filter.class?.value?._id ?? undefined,
        quizId: filter.quiz?.id ?? '',
        roles,
      },
      {
        skip:
          (isTeacher ? !filter.class?.value?._id : !filter.track?.value?._id) ||
          !filter.quiz,
      },
    );
  const { currentAssignments, pastAssignments, upcomingAssignments } =
    useTeacherAndSchooladminUpdatedAssignments(quizTrack);
  const { data: problemSkills, isFetching: isFetchingProblemSkills } =
    useGetSkillsForProblemsQuery(
      { problems: filter.quiz?.problems ?? [] },
      {
        skip: !!(
          !filter.quiz?.problems ||
          (filter.quiz?.problems && filter.quiz?.problems?.length < 1)
        ),
      },
    );

  const skillsData = useMemo(
    () => ({
      skills: problemSkills?.flatMap((p) => p.skills),
      results: quizStats?.skillResults,
    }),
    [problemSkills, quizStats],
  );
  const skillCloudData = useSkillCloudData(skillsData);
  const shouldShowSkillCloud = !!(
    !isFetchingQuizStats &&
    !isFetchingProblemSkills &&
    quizStats?.skillResults &&
    quizStats?.skillResults.length > 0 &&
    skillCloudData
  );

  useEffect(() => {
    if (tracks && tracks[0]) {
      setFilter((previousState) => ({
        ...previousState,
        track: { label: tracks[0].track, value: tracks[0] },
      }));
    }
  }, [tracks]);

  useEffect(() => {
    const trackDocument = getTrackDocument(filter.track?.value._id);
    if (isSchoolAdmin && trackDocument && trackDocument.classes) {
      setFilter((previousState) => ({
        ...previousState,
        class: {
          label: `Class ${trackDocument.classes![0].class}`,
          value: trackDocument.classes![0],
        },
      }));
    } else if (classes && classes[0]) {
      setFilter((previousState) => ({
        ...previousState,
        class: { label: `Class ${classes[0].class}`, value: classes[0] },
      }));
    }
  }, [classes, filter.track]);

  useEffect(() => {
    let title = 'Assignments';
    title = isSchoolAdmin
      ? title + ` - ${filter.track?.value?.track ?? ''}`
      : title +
        ` - Period ${filter.class?.value?.class} - ${filter.class?.value?.trackDetails?.track}`;
    setTitle(title);
    setShowScoreMatrix(false);
    setFilter((previousState) => ({
      ...previousState,
      quiz: undefined,
      student: undefined,
    }));
  }, [filter.class, filter.track]);

  useEffect(() => {
    if (scollToScoresReference.current) {
      scollToScoresReference.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
    setFilter((previousState) => ({
      ...previousState,
      student: undefined,
    }));
  }, [scollToScoresReference, filter.track, filter.class, filter.quiz]);

  const trackFilterOptions: FilterValue<SchoolTrack>[] = [];
  const classFilterOptions: FilterValue<
    ClassDocument & { teacherName?: string }
  >[] = [];
  if (hasBothRoles) {
    const trackDocument = getTrackDocument(filter.track?.value._id);
    if (trackDocument && trackDocument.classes) {
      for (const period of trackDocument.classes) {
        classFilterOptions.push({
          label: `Class ${period.class} (${period.teacherName})`,
          value: period,
        });
      }
    }
  }

  if (isSchoolAdmin) {
    if (tracks && tracks?.length > 0) {
      for (const track of tracks) {
        trackFilterOptions.push({ label: track.track, value: track });
      }
    }
  } else if (isTeacher && !isSchoolAdmin && classes && classes?.length > 0) {
    for (const period of classes) {
      classFilterOptions.push({
        label: `Class ${period.class}`,
        value: period,
      });
    }
  }

  const getActionButtons = (quizId: string, shouldHideScores = false) => {
    const buttons: ActionButton[] = [
      {
        text: 'Practice',
        targetPath: `/quiz/${quizTrack?.serialNum}/${quizId}/practice`,
      },
    ];
    const quiz = getQuiz({ quizId, track: quizTrack, isStudent: false });
    if (!quiz) {
      return;
    }
    if (!shouldHideScores) {
      buttons.unshift({
        text: 'Scores',
        action: () => {
          if (!quiz) {
            return;
          }
          setFilter((previousState) => ({ ...previousState, quiz }));
          setShowScoreMatrix(true);
        },
      });
    }
    if (quiz.checkpointTestUrl) {
      buttons.push({
        text: 'Checkpoint Test',
        action: () => {
          downloadWorkbook({
            quizId: quiz.id,
            url: quiz.checkpointTestUrl,
            isCheckpointWorkbook: true,
          });
        },
      });
    }
    if (quiz.checkpointTestAnswerKeysUrl) {
      buttons.push({
        text: 'Checkpoint Key',
        action: () => {
          downloadWorkbook({
            quizId: quiz.id,
            url: quiz.checkpointTestAnswerKeysUrl,
          });
        },
      });
    }
    return buttons;
  };

  if (isFetchingTracks || isFetchingClassesDetails) {
    return <LoadingBlock />;
  }

  return (
    <div className="grid gap-12">
      <div
        data-testid="heading-section"
        className="grid grid-cols-1 md:grid-cols-2 gap-6 px-6 pb-6 pt-3 justify-items-center shadow-2xl rounded-xl border-tttDefault border-[1px]"
      >
        <div className="grid justify-self-center col-span-1 md:col-span-2 max-w-[90%] justify-items-center items-center text-center overflow-hidden uppercase text-white font-bold md:text-xl lg:text-2xl p-3 -mt-7 shadow-md rounded-xl bg-tttDefault">
          {title}
        </div>
        {isSchoolAdmin && (
          <div className="grid row-start-2 col-span-1 md:col-span-2 w-[90%] justify-items-center">
            <Filter
              filterType={FilterType.trackFilter}
              options={trackFilterOptions}
              selected={filter.track as FilterValue}
              onSelect={(selectedFilter: FilterValue) => {
                setFilter((previousState) => ({
                  ...previousState,
                  track: selectedFilter,
                }));
              }}
            />
          </div>
        )}
        {isTeacher && (
          <div className="grid col-span-1 md:col-span-2 w-[90%] justify-items-center">
            <Filter
              filterType={FilterType.classFilter}
              options={classFilterOptions}
              selected={filter.class as FilterValue}
              onSelect={(selectedFilter: FilterValue) => {
                setFilter((previousState) => ({
                  ...previousState,
                  class: selectedFilter,
                }));
              }}
            />
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 col-span-1 md:col-span-2 justify-items-center gap-6 md:gap-12 w-[90%]">
          {isFetchingQuizTrack ? (
            <div className="box row-start-1 col-span-1 md:col-span-2 p-24 min-h-[600px]">
              <Loading />
            </div>
          ) : (
            quizTrack && (
              <>
                <div className="grid justify-items-center col-start-1 row-start-1 w-full">
                  <List
                    title="Current assignments"
                    data={
                      currentAssignments.length > 0
                        ? currentAssignments.map((q) => {
                            const quiz = getQuiz({
                              quizId: q.id,
                              track: quizTrack,
                              isStudent: false,
                            });
                            return {
                              ...q,
                              quizId: q.id,
                              actionButtons: getActionButtons(q.id),
                              isCheckpointTest: !!q.checkpointTestUrl,
                              checkPointTestInfo: q.isReview
                                ? undefined
                                : getCheckpointTestInfo(quiz),
                            };
                          })
                        : 'No outstanding assignment'
                    }
                    collapsible={true}
                    bgClass={'bg-blue-100'}
                  />
                </div>
                <div className="grid justify-items-center md:row-span-2 row-start-3 col-start-1 md:col-start-2 w-full h-max">
                  <List
                    title="Upcoming assignments"
                    data={
                      upcomingAssignments.length > 0
                        ? upcomingAssignments.map((q) => {
                            const quiz = getQuiz({
                              quizId: q.id,
                              track: quizTrack,
                              isStudent: false,
                            });
                            return {
                              ...q,
                              quizId: q.id,
                              actionButtons: getActionButtons(q.id, true),
                              isCheckpointTest: !!q.checkpointTestUrl,
                              checkPointTestInfo: q.isReview
                                ? undefined
                                : getCheckpointTestInfo(quiz),
                            };
                          })
                        : 'No upcoming assignments'
                    }
                    collapsible={true}
                  />
                </div>
                <div className="grid justify-items-center col-start-1 row-start-2 w-full">
                  <List
                    title="Past assignments"
                    data={
                      pastAssignments.length > 0
                        ? pastAssignments.map((q) => {
                            const quiz = getQuiz({
                              quizId: q.id,
                              track: quizTrack,
                              isStudent: false,
                            });

                            return {
                              ...q,
                              quizId: q.id,
                              actionButtons: getActionButtons(q.id),
                              isCheckpointTest: !!q.checkpointTestUrl,
                              checkPointTestInfo: q.isReview
                                ? undefined
                                : getCheckpointTestInfo(quiz),
                            };
                          })
                        : 'No past assignments'
                    }
                    collapsible={true}
                  />
                </div>
              </>
            )
          )}
        </div>
      </div>
      {showScoreMatrix && (
        <>
          <div data-testid="assignment-results-student-list">
            {filter.student ? (
              // Quiz scores by student
              <QuizScores
                id={filter.student.name}
                caption="Detailed results by quiz and problem"
                dataQuery={{
                  studentId: filter.student.id,
                }}
                isStudentList={false}
                scoresRef={scollToScoresReference}
                showPracticeDetails={true}
                showReviewColumn
                showTime
                actionButtons={{
                  left: {
                    icon: (
                      <Button
                        data-testid={`student-scores-back-button`}
                        action={() =>
                          setFilter((previousState) => ({
                            ...previousState,
                            student: undefined,
                          }))
                        }
                        bgColor={'bg-blue-300'}
                      >
                        <ChevronLeftIcon className="flex h-5 w-5 text-tttDefault hover:cursor-pointer transition ease-in-out duration-300 hover:scale-125" />
                      </Button>
                    ),
                    action: () =>
                      setFilter((previousState) => ({
                        ...previousState,
                        student: undefined,
                      })),
                  },
                }}
              />
            ) : (
              // Student list
              <QuizScores
                id={filter.quiz?.id}
                caption="Detailed results by Student and problem"
                dataQuery={{
                  classId: isTeacher ? filter.class?.value?._id : undefined,
                  trackId: isTeacher ? undefined : filter.track?.value?._id,
                  quizId: filter.quiz?.id,
                  school: isSchoolAdmin ? user.adminOfSchool : undefined,
                }}
                isStudentList
                allowDeletingResult={
                  isTeacher &&
                  currentAssignments
                    .map((a) => a.id)
                    .includes(filter.quiz?.id ?? '')
                }
                allowPushingThroughReview={
                  (isTeacher || isSchoolAdmin) &&
                  currentAssignments
                    .map((a) => a.id)
                    .includes(filter.quiz?.id ?? '')
                }
                scoresRef={scollToScoresReference}
                showPracticeDetails={true}
                showTotalScores
                showTime
                showReviewColumn
                onClickName={(id, name) =>
                  setFilter((previousState) => ({
                    ...previousState,
                    student: { id, name },
                  }))
                }
              />
            )}
          </div>
          <div
            data-testid="skill-results"
            className="grid grid-cols-2 lg:grid-cols-4 gap-6 px-6 md:px-12 pb-12 pt-3 shadow-xl rounded-xl border-tttDefault border-[1px]"
          >
            <div className="grid justify-self-center text-center col-span-2 lg:col-span-4 max-w-[90%] justify-items-center items-center overflow-hidden uppercase text-white font-bold md:text-xl lg:text-2xl p-3 -mt-7 shadow-md rounded-xl bg-tttDefault">
              Skill results - {filter.quiz?.id}
            </div>
            {isFetchingQuizStats || isFetchingProblemSkills ? (
              <div className="box col-span-2 md:col-span-4 p-24 justify-self-center">
                <Loading />
              </div>
            ) : shouldShowSkillCloud ? (
              <div
                data-testid="skill-cloud"
                className="grid justify-self-center col-start-1 col-span-2 lg:col-span-4 p-3 text-sm md:text-base lg:text-lg overflow-x-auto w-full"
              >
                <SkillCloud data={skillCloudData} />
              </div>
            ) : (
              <div className="col-span-2 lg:col-span-4 justify-self-center text-center p-6 text-gray-500">
                No skill results available for the selected quiz.
              </div>
            )}
          </div>
        </>
      )}
      {isTeacher && (
        <div
          data-testid="student-statistics-section"
          className="grid grid-cols-2 lg:grid-cols-4 gap-6 px-12 pb-12 pt-3 shadow-xl rounded-xl border-tttDefault border-[1px]"
        >
          <div className="grid justify-self-center text-center col-span-2 lg:col-span-4 max-w-[90%] justify-items-center items-center overflow-hidden uppercase text-white font-bold md:text-xl lg:text-2xl p-3 -mt-7 shadow-md rounded-xl bg-tttDefault">
            Student statistics
          </div>
          {isFetchingQuizStats ? (
            <div className="box col-span-2 md:col-span-4 p-24 justify-self-center">
              <Loading />
            </div>
          ) : (
            <div
              data-testid="missed-assignments"
              className="grid justify-self-center col-start-1 col-span-2 rounded-xl bg-blue-50 lg:col-span-4 p-6 md:text-xl w-full md:w-[75%] lg:w-[65%]"
            >
              <div className="text-center font-bold pb-3">
                Missed assignments
              </div>
              {quizTrack?.studentsMissed &&
              quizTrack.studentsMissed.length > 0 ? (
                <div className="grid divide-y divide-blue-400 md:p-2">
                  {quizTrack.studentsMissed.map((student: any) => (
                    <Collapsible
                      key={student.name}
                      transitionTime={200}
                      onOpen={() => (student.open = true)}
                      onClose={() => (student.open = false)}
                      trigger={
                        <CollapsedElement
                          key={student.name}
                          studentName={student.name}
                          numOfMissedQuizzes={student.missedAssignments.length}
                          isOpen={student.open}
                        />
                      }
                    >
                      {student.missedAssignments.map(
                        (quiz: any, index: number) => (
                          <div className="w-full py-1 px-3" key={quiz.quizId}>
                            <div
                              className={`${
                                index ===
                                  student.missedAssignments.length - 1 && 'pb-2'
                              }`}
                            >
                              {quiz.quizId}
                            </div>
                            {index < student.missedAssignments.length - 1 && (
                              <div className="h-[1px] bg-blue-300 mt-1" />
                            )}
                          </div>
                        ),
                      )}
                    </Collapsible>
                  ))}
                </div>
              ) : (
                <div className="text-gray-500 text-sm">
                  Everyone completed all their assignments.
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

interface ElementProperties {
  studentName: string;
  numOfMissedQuizzes: number;
  isOpen: boolean;
}

const CollapsedElement: FC<ElementProperties> = ({
  studentName,
  numOfMissedQuizzes,
  isOpen,
}) => {
  return (
    <div className="flex justify-between w-full py-2 items-center font-medium">
      <div>{studentName}</div>
      <div className="flex items-center">
        {numOfMissedQuizzes}
        {isOpen ? (
          <ChevronUpIcon className="h-5 w-5 text-gray-400 pl-2" />
        ) : (
          <ChevronDownIcon className="h-5 w-5 text-gray-400 pl-2" />
        )}
      </div>
    </div>
  );
};

export default SchooladminTeacherAssignments;
